import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link, NavLink } from "react-router-dom";
import './css/modifClient.css';
import { IoIosArrowBack } from "react-icons/io";
import { useGetCommandesByUserQuery } from "../../redux/features/commandeFeature";
import { useSelector } from "react-redux";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import CommandeItemHome from "../../component/CommandeItemHome";
import moment from "moment";
import { useGetClientByIdQuery } from "../../redux/features/clientFeature";

const CommandeByClient = () => {
  const {id} = useParams(); 
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const {
    data: datas,
    Loading,
    isError,
  } = useGetCommandesByUserQuery(currentUser?.id);

  const { data:client } = useGetClientByIdQuery(id);

const filterCommandesByStatut = (data, clientId, statut) => {
    const filtered = data?.filter((commande) => {
      return commande.clientId === Number(clientId);
    });

    return statut ? filtered?.filter((commande) => {
        return commande.statut === statut;
      })
    : filtered;
  };
  
  // Utilisation
  const filtered = filterCommandesByStatut(datas?.data, id, null);
  const commandeNew = filterCommandesByStatut(filtered, id, 'nouvelle');
  const commandeTermine = filterCommandesByStatut(filtered, id, 'terminee');
  const commandeLivree = filterCommandesByStatut(filtered, id, 'livree');
  

    const totalMontant = filtered?.reduce((acc, item) => acc + item.montant, 0) || 0;
    const totalRestant = filtered?.reduce((acc, item) => acc + item.montant_restant, 0) || 0;
    console.log({filtered});

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>commandes de {client?.nom_complet}</span>
    </div>
  );
  const rightContent = null;

 
  return (
    <Layout
    leftContent={leftContent}
    centerContent={centerContent}
    rightContent={rightContent}
  >
    <div style={{ marginTop: "100px"}} className="mx-2">
    {Loading ? (
  <SkeletonComponent />
) : isError ? (
  <p
    style={{
      marginTop: "100px",
      color: "red",
      padding: "30px",
    }}
  >
    Une erreur s'est produite lors du chargement des données.
  </p>
) : (
  <>
    {filtered && filtered?.length > 0 ? (
      <>
        {filtered?.map((item, index) => (
          <Link
            key={index}
            to={`/detail-commande/${item?.id}`}
            style={{ color: "black" }}
          >
            <CommandeItemHome
              key={index}
              name={item?.client?.nom_complet}
              date={moment(item?.date_livraison).format("DD/MM/YYYY HH:mm")}
              status={item?.statut}
              montant={item?.montant}
              montant_paye={item?.montant_paye}
              montant_restant={item?.montant_restant}
            />
          </Link>
        ))}
        <div className="mx-1 my-4 mb-5">
          <span>les informations commandes</span>
          <p className="mx-2">
            <span className="big-title">Prix de total des commandes</span> :{" "}
            {totalMontant} Fcfa
          </p>
          <p className="mx-2">
            <span className="big-title">Montant restant</span> : {totalRestant} Fcfa
          </p>
          <p className="mx-2">
            <span className="big-title">Ces nouvelles commandes</span> :{" "}
            {commandeNew?.length || 0}{" "}
          </p>
          <p className="mx-2">
            <span className="big-title">commandes deja livrées</span> :{" "}
            {commandeLivree?.length || 0}{" "}
          </p>
          <p className="mx-2">
            <span className="big-title">commandes terminées</span> :{" "}
            {commandeTermine?.length || 0}{" "}
          </p>
        </div>
      </>
    ) : (
      <p
        className=""
        style={{
          marginTop: "100px",
          color: "red",
          padding: "30px",
        }}
      >
        Pas de commande disponible pour
        {/* {item?.client?.nom_complet} */}
      </p>
    )}
  </>
)}
      </div>
  </Layout>
  );
};

export default CommandeByClient;

