import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { useGetParametreByUserQuery } from '../../redux/features/paramettreFeature';

export default function SelectInput({ value, onChange }) {
  const currentUser = useSelector((state) => state?.UserSlice?.info);

  const {
    data: paramettre,
    Loading,
    isError,
    // refetch,
  } = useGetParametreByUserQuery(currentUser?.id);

  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value);
  };

  if (Loading) {
    return <p>Loading...</p>; // Afficher un indicateur de chargement si les données sont en cours de chargement
  }

  if (isError) {
    return <p>Error loading data</p>; // Afficher un message d'erreur si une erreur se produit pendant le chargement des données
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">Mesure</InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        defaultValue={value}

        value={value || ''} // Utiliser la valeur contrôlée si elle est définie
        label="Mesure"
        onChange={handleChange}
        
      >
        {paramettre?.map((item) =>
          item?.mesure_title?.map((mesure) => (
            <MenuItem key={mesure} value={mesure}>
              {mesure}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}
