import React, { useState } from "react";
import {
  CLIENTS_PATH,
  DETAIL_CLIENT_PATH,
} from "../../routes/path/navigation_path";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetClientByIdQuery,
  useUpdateClientMutation,
} from "../../redux/features/clientFeature";
import { useParams, useNavigate } from "react-router-dom";
import CustomInput from "../../component/Input";
// import Header from "../../component/Header";
import { IoIosArrowBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import MesureComponent from "../../component/MesureComponent";
import { current } from "@reduxjs/toolkit";
import Layout from "../../component/templateMobile/TemplateMobile";
import { FiPlus } from "react-icons/fi";
import "./css/modifClient.css";
import SelectInput from "../../utils/Material-ui/InputSelect";
// import MesureComponent from "../../component/MesureComponent";

const UpdateClient = () => {
  const { id } = useParams();
  //   console.log(id);
  const { data: currentClient } = useGetClientByIdQuery(id);
  const [updateClient] = useUpdateClientMutation(id);
  const dispatch = useDispatch();

  console.log("donner client", currentClient);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    control,
  } = useForm({
    values: {
      nom_complet: currentClient?.nom_complet,
      telephone: currentClient?.telephone,
      adresse: currentClient?.adresse,
      pays: currentClient?.pays,
      Ville: currentClient?.Ville,
      cou: currentClient?.cou,
      epaule: currentClient?.epaule,
      longueur_manche: currentClient?.longueur_manche,
      ceinture: currentClient?.ceinture,
      tour_bras: currentClient?.tour_bras,
      hanche: currentClient?.hanche,
      longueur_jupe: currentClient?.longueur_jupe,
      longueur_robe: currentClient?.longueur_robe,
      longueur_poitrine: currentClient?.longueur_poitrine,
      taille: currentClient?.taille,
      cuisse: currentClient?.cuisse,
      longueur_haut: currentClient?.longueur_haut,
      uniteMesure: currentClient?.uniteMesure,
      nomMesure: currentClient?.nomMesure,
    },
    defaultValues: {
      nom_complet: currentClient?.nom_complet,
      telephone: currentClient?.telephone,
      adresse: currentClient?.adresse,
      pays: currentClient?.pays,
      Ville: currentClient?.Ville,
      cou: currentClient?.cou,
      epaule: currentClient?.epaule,
      longueur_manche: currentClient?.longueur_manche,
      ceinture: currentClient?.ceinture,
      tour_bras: currentClient?.tour_bras,
      hanche: currentClient?.hanche,
      longueur_jupe: currentClient?.longueur_jupe,
      longueur_robe: currentClient?.longueur_robe,
      longueur_poitrine: currentClient?.longueur_poitrine,
      taille: currentClient?.taille,
      cuisse: currentClient?.cuisse,
      longueur_haut: currentClient?.longueur_haut,
      uniteMesure: currentClient?.uniteMesure,
      nomMesure: currentClient?.nomMesure,
    },
  });
  const currentUser = useSelector((state) => state.UserSlice?.info);
  // Remplacez par la mutation d'actualisation appropriée depuis votre API
  const navigate = useNavigate();

  const leftContent = (
    <div style={{ cursor: "pointer", color: "black" }}>
      <IoIosArrowBack size={24} onClick={() => navigate(-1)} />
    </div>
  );
  const centerContent = (
    <div className="titre-header">
      <span>Modifier le Client</span>
    </div>
  );
  const rightContent = null;

  const onSubmit = async (data) => {
    setLoading(true);
    // Eclgffectuez ici la logique d'actualisation du client avec les données de "data"
    try {
      // Utilisez la mutation d'actualisation pour mettre à jour le client
      const response = await updateClient({
        id: currentClient.id,
        body: data,
      });
      // Assurez-vous d'ajuster les paramètres de la mutation d'actualisation
      console.log("Client mis à jour :", response);
      toast.success("Client modifié avec succès !"); // Utilisez toast pour l'alerte de succès
      setTimeout(() => {
        setLoading(false); // Définissez l'état de chargement sur false après la fin de la requête
        navigate(-1);
      }, 1000);
      // Redirigez l'utilisateur vers la page de détails du client ou une autre page appropriée
    } catch (error) {
      console.error("Erreur lors de la mise à jour du client :", error);
      // Gérez les erreurs d'actualisation ici
      setLoading(false);
    }
  };
  const [mesures, setMesures] = useState(currentClient?.mesure_client || []); // Assurez-vous que currentClient est défini

  const ajouterChampMesure = () => {
    setMesures([...mesures, { nomMesure: "", uniteMesure: "" }]);
  };

  const supprimerChampMesure = (index) => {
    const nouvellesMesures = [...mesures];
    nouvellesMesures.splice(index, 1);
    setMesures(nouvellesMesures);
  };

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className=" border  p-3 shadow ">
        <ToastContainer />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {" "}
            <label className="form-label ">Nom complet</label>
          </div>

          <CustomInput
            type="text"
            name="nom_complet"
            id="nom_complet"
            className="form-control mb-2"
            register={{
              ...register("nom_complet", {
                required: "champs obligatoire",
              }),
            }}
            defaultValue={currentClient?.nom_complet}
            error={errors.nom_complet}
          />
          {errors.nom_complet && (
            <p style={{ color: "red" }}>{errors.nom_complet.message}</p>
          )}

          <div>
            {" "}
            <label className="form-label ">Téléphone</label>
          </div>
          <CustomInput
            type="number"
            name="telephone"
            id="telephone"
            className="form-control"
            register={{
              ...register("telephone", {
                required: "Champs obligatoire",
                pattern: {
                  value: /^[0-9]{9}$/, // Expression régulière pour 9 chiffres exacts
                  message:
                    "Le numéro de téléphone doit comporter exactement 9 chiffres.",
                },
              }),
            }}
            defaultValue={currentClient?.telephone}
            error={errors.telephone}
          />
          {errors.telephone && (
            <p style={{ color: "red" }}>{errors.telephone.message}</p>
          )}

          <div>
            <label className="form-label ">Addresse</label>
          </div>
          <CustomInput
            type="text"
            name="adress"
            id="addresse"
            className="form-control"
            register={{
              ...register("adresse", {
                // required: "champs obligatoire",
              }),
            }}
            defaultValue={currentClient?.adresse}
            error={errors.adresse}
          />
          {errors.adresse && (
            <p style={{ color: "red" }}>{errors.adresse.message}</p>
          )}
          <div>
            {" "}
            <label className="form-label">Pays</label>
          </div>
          <CustomInput
            type="text"
            name="pays"
            id="pays"
            className="form-control"
            register={{
              ...register("pays", {
                // required: "champs obligatoire",
              }),
            }}
            defaultValue={currentClient?.pays}
            error={errors.pays}
          />
          {errors.pays && <p style={{ color: "red" }}>{errors.pays.message}</p>}
          <div>
            <label className="form-label">Ville</label>
          </div>
          <CustomInput
            type="text"
            name="ville"
            id="Ville"
            className="form-control"
            register={{
              ...register("Ville", {
                // required: "champs obligatoire",
              }),
            }}
            defaultValue={currentClient?.Ville}
          />
          {errors.Ville && (
            <p style={{ color: "red" }}>{errors.Ville.message}</p>
          )}
          <div className="">
            <div style={{ textAlign: "center", marginTop: "5px" }}>
              <span className="sub-title">Ces mesures</span>
            </div>
            <div className="d-flex justify-content-center ">
              <button
                type="button"
                className="btn btn-danger mb-2"
                onClick={ajouterChampMesure}
                style={{ width: "46px" }}
              >
                <FiPlus />
              </button>
              <br />
            </div>
            {mesures ? (
              mesures.map((item, index) => (
                <div
                  key={index}
                  className="row d-flex col-12 justify-content-between colcommande"
                >
                  <div className="inputCommande d-flex align-items-center">
                    <div className="mr-2 flex-grow-1">
                  
                      <Controller
                        name={`mesure_client[${index}].nomMesure`}
                        control={control}
                        defaultValue={item?.nomMesure}
                        render={({ field }) => (
                          <SelectInput
                            value={field.value}
                            onChange={(selectedValue) =>
                              field.onChange(selectedValue)
                            }
                          />
                        )}
                      />
                    </div>
                    <div className="mr-2 flex-grow-1">
                      <Controller
                        name={`mesure_client[${index}].uniteMesure`}
                        control={control}
                        defaultValue={item.uniteMesure}
                        render={({ field }) => (
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ajouter la valeur"
                            {...field}
                            style={{ width: "120px" }}
                          />
                        )}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-danger mb-2"
                      style={{ width: "46px" }}
                      onClick={() => supprimerChampMesure(index)}
                    >
                      -
                    </button>
                  </div>
                </div>
              ))
            ) : currentClient?.sexe === "femme" ? (
              <>
                <div className="col-md-6 col-12 colcommande">
                  <label className="form-label mt-3">Cou</label>
                  <CustomInput
                    type="number"
                    name="cou"
                    id="cou"
                    className="form-control"
                    register={{
                      ...register("cou", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.cou}
                  />
                  {errors.cou && (
                    <p style={{ color: "red" }}>{errors.cou.message}</p>
                  )}

                  <label className="form-label mt-3">Epaule</label>
                  <CustomInput
                    type="number"
                    name="Epaule"
                    id="Epaule"
                    className="form-control"
                    register={{
                      ...register("epaule", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.epaule}
                  />
                  {errors.epaule && (
                    <p style={{ color: "red" }}>{errors.epaule.message}</p>
                  )}
                  <label className="form-label mt-3">Manche</label>
                  <CustomInput
                    type="number"
                    name="MANCHE"
                    id="manche"
                    className="form-control"
                    register={{
                      ...register("longueur_manche", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.longueur_manche}
                  />
                  {errors.longueur_manche && (
                    <p style={{ color: "red" }}>
                      {errors.longueur_manche.message}
                    </p>
                  )}
                  <label className="form-label mt-3">Ceinture</label>
                  <CustomInput
                    type="number"
                    name="Ceinture"
                    id="ceinture"
                    className="form-control"
                    register={{
                      ...register("ceinture", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.ceinture}
                  />
                  {errors.ceinture && (
                    <p style={{ color: "red" }}>{errors.ceinture.message}</p>
                  )}
                  <label className="form-label mt-3">Tour de bras</label>
                  <CustomInput
                    type="number"
                    name="TOUR BRAS"
                    id="tour_bras"
                    className="form-control"
                    register={{
                      ...register("tour_bras", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.tour_bras}
                    // text="TOURS DE BRAS :"
                    // Texvalue={currentClient?.tour_bras}
                  />
                  {errors.tour_bras && (
                    <p style={{ color: "red" }}>{errors.tour_bras.message}</p>
                  )}
                  <label className="form-label mt-3">Hanche</label>
                  <CustomInput
                    type="number"
                    name="HANCHE"
                    id="hanche"
                    className="form-control"
                    register={{
                      ...register("hanche", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.hanche}
                  />
                  {errors.hanche && (
                    <p style={{ color: "red" }}>{errors.hanche.message}</p>
                  )}
                </div>
                <label className="form-label mt-3">Longueur Jupe</label>
                <div className="col-md-6 col-12">
                  <CustomInput
                    type="number"
                    name="longueur_jupe"
                    id="longueur_jupe"
                    className="form-control"
                    register={{
                      ...register("longueur_jupe", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.longueur_jupe}
                  />
                  {errors.longueur_jupe && (
                    <p style={{ color: "red" }}>
                      {errors.longueur_jupe.message}
                    </p>
                  )}
                  <label className="form-label mt-3">Longueur Robe</label>
                  <CustomInput
                    type="number"
                    name="longueur_robe"
                    id="longueur_robe"
                    className="form-control"
                    register={{
                      ...register("longueur_robe", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.longueur_robe}
                  />
                  {errors.longueur_robe && (
                    <p style={{ color: "red" }}>
                      {errors.longueur_robe.message}
                    </p>
                  )}
                  <label className="form-label mt-3">Poitrine</label>
                  <CustomInput
                    type="number"
                    name="poitrine"
                    id="poitrine"
                    className="form-control"
                    register={{
                      ...register("longueur_poitrine", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.longueur_poitrine}
                  />
                  {errors.longueur_poitrine && (
                    <p style={{ color: "red" }}>
                      {errors.longueur_poitrine.message}
                    </p>
                  )}
                  <label className="form-label mt-3">Taille</label>
                  <CustomInput
                    type="number"
                    name="taille"
                    id="taille"
                    className="form-control"
                    register={{
                      ...register("taille", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.taille}
                  />
                  {errors.taille && (
                    <p style={{ color: "red" }}>{errors.taille.message}</p>
                  )}
                  <label className="form-label mt-3">Cuisse</label>
                  <CustomInput
                    type="number"
                    name="cuisse"
                    id="cuisse"
                    className="form-control"
                    register={{
                      ...register("cuisse"),
                    }}
                    defaultValue={currentClient?.cuisse}
                  />
                  {errors.cuisse && (
                    <p style={{ color: "red" }}>{errors.cuisse.message}</p>
                  )}
                  <label className="form-label mt-3">Longueur Haut</label>
                  <CustomInput
                    type="number"
                    name="longueur_haut"
                    id="longueur_haut"
                    className="form-control"
                    register={{
                      ...register("longueur_haut", {
                        validate: (value) => {
                          if (value && value.length > 2) {
                            return "Le nombre ne doit pas dépasser deux chiffres.";
                          }
                          return true; // Validation réussie
                        },
                      }),
                    }}
                    defaultValue={currentClient?.longueur_haut}
                  />
                  {errors.longueur_haut && (
                    <p style={{ color: "red" }}>
                      {errors.longueur_haut.message}
                    </p>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* Champs pour homme ou autre */}
                {/* <div className="col-md-6 col-12">
                              <CustomInput
                                type="number"
                                name="COU"
                                id="cou"
                                className="form-control"
                                {...register("Cou", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.cou}
                              />
                              <CustomInput
                                type="number"
                                name="EPAULE"
                                id="epaule"
                                className="form-control"
                                {...register("epaule", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.epaule}
                              />
                              <CustomInput
                                type="text"
                                name="MANCHE"
                                id="manche"
                                className="form-control"
                                {...register("manche", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.manche}
                              />
                              <CustomInput
                                type="text"
                                name="Ceinture"
                                id="ceinture"
                                className="form-control"
                                {...register("ceinture", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.ceinture}
                              />
                              <CustomInput
                                type="text"
                                name="TOUR BRAS"
                                id="tour_bras"
                                className="form-control"
                                {...register("tour_bras", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.tour_bras}
                              />
                              <CustomInput
                                type="text"
                                name="HANCHE"
                                id="hanche"
                                className="form-control"
                                {...register("hanche", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.hanche}
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <CustomInput
                                type="number"
                                name="BOUBOU"
                                id="boubou"
                                className="form-control"
                                {...register("boubou", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.boubou}
                              />
                              <CustomInput
                                type="number"
                                name="PANTALON"
                                id="pantalon"
                                className="form-control"
                                {...register("pantalon", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.longueur_pantalon}
                              />
                              <CustomInput
                                type="number"
                                name="POITRINE"
                                id="poitrine"
                                className="form-control"
                                {...register("poitrine", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.poitrine}
                              />
                              <CustomInput
                                type="number"
                                name="TAille"
                                id="taille"
                                className="form-control"
                                {...register("taille", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.taile}
                              />
                              <CustomInput
                                type="number"
                                name="CUISSE"
                                id="cuisse"
                                className="form-control"
                                {...register("cuisse", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.cuisse}
                              />
                              <CustomInput
                                type="number"
                                name="LONGUEUR HAUT"
                                id="longueur_haut"
                                className="form-control"
                                {...register("longueur_haut", {
                                  required: "champs obligatoire",
                                })}
                                defaultValue={currentClient?.longueur_haut}
                              />
                            </div> */}
              </>
            )}
          </div>

          <div className="text-center ">
            <br></br>
            <button
              type="submit"
              className="btn btn-danger text-light "
              // className="validateButton mt-2" type="submit" style={{ width: "91%", marginLeft: '16px' }} disabled={loading}
            >
              {loading ? (
                <div className="spinner-border text-white" role="status"></div>
              ) : (
                " Mettre à jour le client"
              )}
            </button>
            {/* <button
              onClick={() => {
                console.log("sub");
              }}
              type="submit"
              className="btn btn-danger text-light "
            >
              Mettre à jour le client
            </button> */}
          </div>
        </form>
      </div>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  );
};

export default UpdateClient;
