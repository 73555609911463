import React, { useState,useEffect } from "react";
import CommandeItemHome from "../../component/CommandeItemHome";
import "./accueil.css";
import { useGetCommandesByUserQuery } from "../../redux/features/commandeFeature";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import {
  ADD_COMMANDE_PATH,
  CLIENTS_PATH,
  COMMANDES_PATH,
  NOTIFICATION_PATH,
  PROFIL_PATH,
} from "../../routes/path/navigation_path";
import Header from "../../component/templateMobile/Header";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import "../../utils/GlobalStyle.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { useGetClientsQuery } from "../../redux/features/clientFeature";
import { Badge, Box, Card, CardContent, Grid, Typography } from "@mui/material";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import Tab from "@mui/material/Tab";
import { setCommandesLivraisonToDay, setCommandesPassed } from "../../redux/slices/commandeSlice";
import ButtonAjout from "../../utils/Material-ui/buttonAjout";

const Accueil = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  // const { donnees: donne } = useGetClientsQuery(currentUser?.id);
  // const client = donne?.data.filter(item => item.statut === "terminee")
  // console.log('client',client);
console.log(currentUser?.id);
  const {
    data: datas,
    isLoading,
    isError,
    refetch,
  } = useGetCommandesByUserQuery(currentUser?.id);

  const { data: dataClient } = useGetClientsQuery(currentUser?.id);

  const { data, error } = useGetClientsQuery(currentUser?.id);

  // Déclarez filteredCommandes en dehors de la portée du if
  let filteredCommandes = [];
  let copiedData = [];

  if (Array?.isArray(datas?.data)) {
    // Si datas?.data est un tableau, effectuez la copie
    copiedData = [...datas?.data];
    // Filtrer les commandes par date de livraison dans l'ordre croissant
    filteredCommandes = copiedData
      .slice(0, 10)
      .sort((a, b) => new Date(b.date_livraison) - new Date(a.date_livraison));
    // Ensuite, vous pouvez effectuer d'autres opérations sur copiedData
  } else {
    // Gérez le cas où datas?.data n'est pas un tableau
    console.error("datas?.data is not an array");
  } 
  // console.log("datas.clients.length", datas.clients.length);
  const commandesLivraisonAujourdhui = useSelector(
    (state) => state.commandesSlice.commandesLivraisonAujourdhui
  );
  const commandesPasses = useSelector(
    (state) => state.commandesSlice.commandesPasses
  );
  const firstLastName = currentUser?.firstName?.charAt(0) + currentUser?.lastName?.charAt(0) || "";
  const leftContent = (
    <Link to={PROFIL_PATH}>
      <div style={{ display: 'flex',  flexDirection: 'column', alignItems: 'center' }}>
      <img
        src={require("../../assets/img/profile2.png")}
        alt="Profile"
        className="profile-img"
        style={{ width: 55, height: 55, marginTop: "-4px" }}
      />
      <span style={{marginTop: "-18px", color:"#B9B8B8"}}>{firstLastName}</span>
      </div>
    </Link>
  );
  // Ajoutez une fonction pour vérifier si la commande est payée
 

  const centerContent = (
    <div className="titre-header">
      <span>Accueil</span>
    </div>
  );

  const rightContent = (
    <div>
      <Badge
        badgeContent={
          (Number(commandesPasses) || 0) +
          (Number(commandesLivraisonAujourdhui) || 0)
        }
        color="success"
      >
        <NotificationsNoneIcon
          style={{ fontSize: "35px", color: "#8E8EA1" }}
          onClick={() => navigate(NOTIFICATION_PATH)}
        />
      </Badge>
    </div>
  );

  const [value, setValue] = useState("1"); // Utilisez un état local pour gérer la valeur de l'onglet actif

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [commandesPasse, setCommandesPasses] = useState([]);
  const [commandesALivraisonAujourdhui, setCommandesALivraisonAujourdhui] = useState([]);
  const [commandesSemaine, setCommandesSemaine] = useState([]);

  useEffect(() => {
    if (datas && datas?.data) {
      const commandes = datas?.data;
      const dateAujourdhui = moment().startOf("day"); // Date d'aujourd'hui à minuit

      // Filtrer les commandes à livrer aujourd'hui
      const commandesLivraisonAujourdhuis = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (commande.statut !== "livree" && dateLivraison.isSame(dateAujourdhui, "day")) {
          return true;
        }
        return false;
      });

      // Filtrer les commandes passées (antérieures à aujourd'hui)
      const commandesPasse = commandes.filter((commande) => {
        const dateLivraison = moment(commande.date_livraison);
        if (commande.statut !== "livree" && dateLivraison.isBefore(dateAujourdhui, "day")) {
          return true;
        }
        return false;
      });
      
// Obtenez la date d'aujourd'hui
const datePourAujourdhui = moment();

// Obtenez le début et la fin de la semaine
const debutSemaine = datePourAujourdhui.clone().startOf("isoWeek");
const finSemaine = datePourAujourdhui.clone().endOf("isoWeek");

// Filtrer les commandes à livrer cette semaine
const commandesSemaine = commandes.filter((commande) => {
  const dateLivraison = moment(commande.date_livraison);
  // Vérifiez si la date de livraison est comprise entre le début et la fin de la semaine
  if (
    commande.statut !== "livree" &&
    dateLivraison.isBetween(debutSemaine, finSemaine, undefined, "[)")
  ) {
    return true;
  }

  return false;
});
console.log({commandesSemaine});

      dispatch(setCommandesLivraisonToDay(commandesLivraisonAujourdhui?.length));
      dispatch(setCommandesPassed(commandesPasse?.length));
      setCommandesPasses(commandesPasse);
      setCommandesALivraisonAujourdhui(commandesLivraisonAujourdhuis);
      setCommandesSemaine(commandesSemaine);
    }
  }, [datas]);
  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <ButtonAjout onClick={() => navigate(ADD_COMMANDE_PATH)} />
      <div className="homes">
        <Grid container className="mt-1">
          <Grid item xs={6} md={6}>
            <Link 
            // to={COMMANDES_PATH}
             style={{ textDecoration: "none" }}>
              <div className="sec1">
                <DataThresholdingOutlinedIcon
                  className="iconss"
                  style={{ color: "#E82828" }}
                />
                <div className="">
                  <div>
                    <span className="solde-text" style={{ fontSize: "15px", color: "#888888", fontWeight: 600 }}>Solde</span>
                  </div>
                  <div>
                    <div className="lessolde">
                      {datas?.prixTotal.toLocaleString()}
                      <span style={{ fontSize: "10px" }}> Fcfa</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item xs={6} md={6}>
            <Link to={COMMANDES_PATH} style={{ textDecoration: "none" }}>
              <div className="sec1">
                <ShoppingCartIcon
                  className="iconss"
                  style={{ color: "#E82828" }}
                />
                <div className="">
                  <div>
                  <span className="solde-text" style={{ fontSize: "15px", color: "#888888", fontWeight: 600 }}>Command{datas?.data?.length <= 1 ? 'e' : 'es'}</span>
                  </div>
                  <div>
                    <span className="lessolde">{datas?.data?.length}</span>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
          <Grid item xs={6} md={6}>
            <Link to={CLIENTS_PATH} style={{ textDecoration: "none" }}>
              <div className="sec1">
                <PeopleAltOutlinedIcon
                  className="iconss"
                  style={{ color: "#E82828" }}
                />
                <div className="">
                  <div>
                    <span className="solde-text" style={{ fontSize: "15px", color: "#888888", fontWeight: 600,  marginRight: "30px" }}>Clien{dataClient?.length <= 1 ? 't' : 'ts'}</span>
                  </div>
                  <div>
                    <div className="lessolde">
                      {dataClient?.length}
                      {/* <span style={{ fontSize: "10px" }}> Fcf</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>

          <Grid item xs={6} md={6}>
            <Link
            //  to={COMMANDES_PATH} 
             style={{ textDecoration: "none" }}>
              <div className="sec1">
                <DataThresholdingOutlinedIcon
                  className="iconss"
                  style={{ color: "#E82828" }}
                />
                <div className="">
                  <div>
                    <span className="solde-text" style={{ fontSize: "15px", color: "#888888", fontWeight: 600,  marginRight: "30px" }}>Restant</span>
                  </div>
                  <div>
                    <div className="lessolde" >
                      {datas?.totalRestant.toLocaleString()}
                      <span style={{ fontSize: "10px" }}>  Fcfa</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Grid>
        </Grid>
        <div className="commande-lists" style={{fontFamily:"Montserrat"}}>
        <div style={{ margin: "5px", color: "#000" }}>
              <span className="title-en-tete">Livraison des commandes</span>
            </div>
        <Box sx={{ width: "100%", typography: "body1",  }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around', borderBottom: 1, borderColor: "divider", fontFamily:"Montserrat"}}>
            <Tab
              // label="Aujourd'hui"
              label={<Badge badgeContent={commandesALivraisonAujourdhui?.length} color="error">Aujourd'hui</Badge>}
              className="solde-text"
              value="1"
              onClick={(e) => handleChange(e, "1")}
              style={value ==="1" ?   { borderBottom: "1px solid red", color: "red", fontWeight: 600 }
              : {
                  fontSize: "11px",
                  color: "#888888",
                  fontWeight: 600,
                }
                 }
            />
             <Tab
              // label="Cette semaine"
              label={<Badge badgeContent={commandesSemaine?.length} color="error">Cette semaine</Badge>}
              className="solde-text"
              value="2"
              onClick={(e) => handleChange(e, "2")}
              style={value ==="2" ? {borderBottom: "1px solid red", color:"red",fontWeight: 600} : {fontSize: "11px", color: "#888888", fontWeight: 600}}
            />
            <Tab
              // label="Non livrée"
              label={<Badge badgeContent={commandesPasse?.length} color="error">Non livrée:</Badge>}
              className="solde-text"
              value="3"
              onClick={(e) => handleChange(e, "3")}
              style={value ==="3" ? {borderBottom: "1px solid red", color:"red",fontWeight: 600} : {fontSize: "11px", color: "#888888", fontWeight: 600}}
            />
          </Box>
        </Box>
        {value === "3" && (
          <div>
            {isLoading ? (
              <SkeletonComponent />
            ) : isError ? (
              <p
                style={{
                  marginTop: " 500px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Une erreur s'est produite lors du chargement des données.
              </p>
            ) : commandesPasse && commandesPasse?.length > 0 ? (
              commandesPasse?.map((commande, index) => (
                <Link
                key={index}
                to={`/detail-commande/${commande?.id}`}
                style={{ textDecoration: "none" }}
                >
                <CommandeItemHome
                  key={index}
                  name={commande?.client?.nom_complet}
                  date={moment(commande?.date_livraison).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                  status={commande?.statut}
                  montant={commande?.montant}
                  montant_paye={commande?.montant_paye}
                  montant_restant={commande?.montant_restant}
                />
                </Link>
              ))
            ) : (
              <p
                className=""
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Toutes vos commandes ont étaient livrées à temps. 🚚🎉
              </p>
            )}
          </div>
        )}
        {value === "1" && (
          <div>
            {isLoading ? (
              <SkeletonComponent />
            ) : isError ? (
              <p
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Une erreur s'est produite lors du chargement des données.
              </p>
            ) : commandesALivraisonAujourdhui &&
            commandesALivraisonAujourdhui?.length > 0 ? (
              commandesALivraisonAujourdhui?.map((commande, index) => (
                <Link
                key={index}
                to={`/detail-commande/${commande?.id}`}
                style={{ textDecoration: "none" }}
                >
                <CommandeItemHome
                  key={index}
                  name={commande.client.nom_complet}
                  date={moment(commande.date_livraison).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                  status={commande?.statut}
                  montant={commande?.montant}
                  montant_paye={commande?.montant_paye}
                  montant_restant={commande?.montant_restant}
                />
                </Link>
              ))
            ) : (
              <p
                className=""
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Pas de commandes à livrer pour aujourd'hui. 👌
              </p>
            )}
          </div>
        )}
         {value === "2" && (
          <div>
            {isLoading ? (
              <SkeletonComponent />
            ) : isError ? (
              <p
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Une erreur s'est produite lors du chargement des données.
              </p>
            ) : commandesSemaine &&
            commandesSemaine?.length > 0 ? (
              commandesSemaine?.map((commande, index) => (
                <Link
                key={index}
                to={`/detail-commande/${commande?.id}`}
                style={{ textDecoration: "none" }}
                >
                <CommandeItemHome
                  key={index}
                  name={commande?.client.nom_complet}
                  date={moment(commande?.date_livraison).format(
                    "DD/MM/YYYY HH:mm"
                  )}
                  status={commande?.statut}
                  montant={commande?.montant}
                  montant_paye={commande?.montant_paye}
                  montant_restant={commande?.montant_restant}
                />
                </Link>
              ))
            ) : (
              <p
                className=""
                style={{
                  marginTop: " 100px",
                  color: "red",
                  padding:"30px"
                }}
              >
                Pas de commandes à livrer pour cette semaine. 👌
              </p>
            )}
          </div>
        )}
        </div>
        <br></br>
        <br></br>
      </div>
    </Layout>
  );
};

export default Accueil;
