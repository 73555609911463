import { createSlice } from "@reduxjs/toolkit"

let initialState = {
    currentCommande: {},
    commandesList: [],
    allCommandesList: [],
    statusFilter: "",
}
const commandesSlice = createSlice({
    name:'commandes',
    initialState,
    reducers:{
        addNewCommande: (state, { payload }) => {
            // Ajoutez la nouvelle commande au début de la liste
            state.commandesList = [payload, ...state.commandesList];
            state.allCommandesList = [payload, ...state.allCommandesList];
        },
        updateCommandeInList:(state,{payload})=>{
            let commandes = state.allCommandesList.filter(commande => commande.id !== payload.id)
            commandes = [payload,...commandes]
            state.allCommandesList = commandes
            state.commandesList = commandes
        },
        archiveCommandeInList:(state,{payload})=>{
            let commandes = state.allCommandesList.filter(commande => commande.id !== payload.id)
            state.allCommandesList = commandes
            state.commandesList = commandes
        },
        setCurrentCommande:(state,{payload})=>{
            state.currentCommande = payload
        },
        // eslint-disable-next-line no-unused-vars
        ressetCurrentCommande:(state,{payload})=>{
            state.currentCommande = {}
        },
        setCommandesList:(state,{payload})=>{
            state.commandesList = payload
            state.allCommandesList = payload
        },
        filterCommandesList:(state,{payload})=>{
            state.commandesList = state.allCommandesList.filter(item =>{
                let data = [item.nom_complet,item.telephone, item.adresse].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
        setStatusFilter: (state, { payload }) => {
            state.statusFilter = payload;
        }, 
        //pour le badge de notification
        setCommandesPassed: (state, action) => {
            state.commandesPasses = action.payload;
          },
        setCommandesLivraisonToDay: (state, action) => {
        state.commandesLivraisonAujourdhui = action.payload;
        },
    }
})

export const {addNewCommande, setCurrentCommande,ressetCurrentCommande, setCommandesList,filterCommandesList, updateCommandeInList,archiveCommandeInList, setCommandesPassed, setCommandesLivraisonToDay} = commandesSlice.actions
export default commandesSlice.reducer
