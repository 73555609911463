import React,{useState, useEffect} from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import navigation_routes, { public_routes } from "./routes/navigation_routes";
import PrivateRoutes from "./routes/PrivateRoutes";
import BottomTab from "./component/templateMobile/BottomTap";
import "./App.css";
import Modal from "react-modal";
import Layout from "./component/templateMobile/TemplateMobile";
Modal.setAppElement("#root"); 
function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleInstall = () => {
    setOpened(false);
    // Show the prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        console.log({ choiceResult });
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    async function actionInstall(e) {
      e.preventDefault();
      setDeferredPrompt(e);
      setOpened(true);
    }
    window.addEventListener("beforeinstallprompt", actionInstall);
  }, []);

  const location = useLocation();
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/register";

  return (
    <div className="container-fluid m-0 p-0 font-Montserrat">
      <Routes>
        {/* Rendre uniquement les routes publiques en dehors de la condition */}
        {public_routes.map((item, index) => (
          <Route key={index} path={item?.path} element={item?.element} />
       ) )}

        {/* Utilisez une condition pour rendre les routes privées */}
        {!isLoginPage && (
          <Route element={<PrivateRoutes />}>
            {navigation_routes.map((item, index) => (
              <Route key={index} path={item?.path} element={item.element} />
           ) )}
          </Route>
        )}
      </Routes>
        {/* Modal pour l'installation */}
      <Modal
        isOpen={opened}
        onRequestClose={() => setOpened(false)}
        contentLabel="Install App Modal"
        className="alert-modal"  // Ajoutez la classe CSS personnalisée ici
      >
        <div>
          <p>Voulez-vous installer l'application ?</p>
          <button onClick={handleInstall}>Installer</button>
          {/* <button onClick={() => setOpened(false)}>Annuler</button> */}
        </div>
      </Modal>
    </div>
  );
}

export default App;
