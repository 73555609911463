import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useDeleteClientMutation } from "../../redux/features/clientFeature";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../utils/DeleteModal";
import { CLIENTS_PATH } from "../../routes/path/navigation_path";

const DeleteClient = ({ showModal, setShowModal, onDeleted }) => {
  const navigate = useNavigate();
//   const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { id } = useParams();
  // console.log(id);
  

  const [isLoading, setIsLoading] = useState(false);
  const [deleteClient] = useDeleteClientMutation();

  function closeModal() {
    setShowModal(false);
  }

  const handleDeleteClient = () => {
    setIsLoading(true);
  
    deleteClient(id)
      .unwrap()
      .then((data) => {
        if (data) {
          // Suppression réussie
          toast.success("Client supprimé");
          closeModal();
          onDeleted(); // Appeler cette fonction pour indiquer que la client a été supprimée
          navigate(CLIENTS_PATH);
        } else {
          // data est null, mais la suppression a réussi
          closeModal();
          onDeleted();
          navigate(CLIENTS_PATH);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Une erreur s'est produite lors de la suppression du client");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  return (
    
    <DeleteModal
    showModal={showModal}
    setShowModal={setShowModal}
    onClose={closeModal}
    title="Suppression client"
    titleIconClass="white-users-icon"
  >
    <ToastContainer/>
    <div className="p-2">
      <p className="text-center py-3">
        Voulez vous vraiment supprimer ce client?
      </p>
      <div className="text-center" style={{display:'flex', justifyContent:'space-between', flexDirection:'row', margin:'auto'}}>
        <button
          type="submit"
          className=" btn btn-secondary text-white py-2 px-4 mx-3 mt-3"
          onClick={closeModal}
        >
          Annuler
        </button>
        <button
        className="btn text-white py-2 px-4 mx-3 mt-3 text-align-center"
          style={{
            backgroundColor: "#E82828",
            color: "#FFFFFF",
          }}
          disabled={isLoading}
          onClick={handleDeleteClient}
          type="submit"
        >
          {isLoading ? (
            <div className="spinner-border text-light" role="status"></div>
          ) : (
            "Supprimer"
          )}
        </button>
      </div>
    </div>
  </DeleteModal>
  );
};

export default DeleteClient;

