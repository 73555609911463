import React from "react";
import './css/commandeItemHome.css'


const CommandeItemHome = ({ name, date, status, montant, montant_paye, montant_restant }) => {
    let statusColor;
    // let statusText;

    if (status === 'nouvelle') {
      // status === "Nouvelles"
        statusColor = "#E82828";
    } else if (status === 'encours') {
        statusColor = "#F0A113";
    } else if (status === 'terminee') {
      statusColor = " #12C28D";
  }
     else {
        statusColor = "#12C28D"
    }
  
    return (
      <div className="troisElements">
        <div>
          <span className="big-title" style={{color:'#000'}}>{name}</span>
          <p style={{ fontSize: "12px", color: "#888888", fontWeight: 600 , margin:"0"}}>{date}</p>
        </div>
        <p
          style={{
            padding: "5px",
            backgroundColor: statusColor,
            borderRadius: "5px",
            color: "#ffffff",
            width:"75px",
            textAlign:"center"
          }}
        >
            {status.charAt(0).toUpperCase() + status.slice(1)}
        </p>
      </div>
    );
  };
  

export default CommandeItemHome;
