import React, { useState } from "react";
import Layout from "./templateMobile/TemplateMobile";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { FiPlus, FiMinus } from "react-icons/fi";
import "./css/profilTayeur.css";
import { useAddParametreMutation, useGetParametreByUserQuery } from "../redux/features/paramettreFeature";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { PROFIL_PATH } from "../routes/path/navigation_path";
import { addNewParametres } from "../redux/slices/parametreSlice";

function Personaliser() {
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mesures, setMesures] = useState([""]);

  const [ajouterParametre] = useAddParametreMutation();
  const { refetch: refetchUserData } = useGetParametreByUserQuery(currentUser?.id);

  // Vérifie si tous les champs de saisie sont vides
  const areAllInputsEmpty = mesures.every((mesure) => mesure === "");

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Parametrer mes mesures</span>
    </div>
  );
  const rightContent = null;

  const ajouterMesure = () => {
    const newMesures = [...mesures, ""];
    setMesures(newMesures);
  };
// console.log('mesures',mesures);
  const supprimerMesure = (index) => {
    const updatedMesures = mesures.filter((_, i) => i !== index);
    setMesures(updatedMesures);
  };

  const validerMesures = async () => {
    try {
      // Envoie les mesures à l'API
      const response = await ajouterParametre({
        body: {
          userId:currentUser?.id,
          mesure_title: mesures,
        },
      });
      console.log(response);
      dispatch(addNewParametres(response?.data));
      refetchUserData();
      toast.success("Ajout commande succès !"); // Utilisez toast pour l'alerte de succès
      setTimeout(() => {
        navigate(PROFIL_PATH);
      }, 1000);

        console.log("Mesures ajoutées avec succès !");
        // console.log(response.data); 
    } catch (error) {
      // Vérifie le type d'erreur
      if (error.response) {
        // Si la requête a été effectuée et a renvoyé une réponse avec un statut différent de 2xx
        console.error("Erreur lors de l'ajout des mesures. Statut :", error.response);
      } else if (error.request) {
        // Si la requête a été effectuée mais n'a pas reçu de réponse
        console.error("Erreur lors de l'ajout des mesures. Aucune réponse reçue.");
      } else {
        // Erreur générale lors de la configuration de la requête
        console.error("Erreur lors de l'ajout des mesures :", error.message);
      }
    }
  };
  
  

  return (
    <div>
      <Layout
        leftContent={leftContent}
        centerContent={centerContent}
        rightContent={rightContent}
      >
        <div className="container">
        <ToastContainer />
          <div className="iconText">
            <img
              src={require("../assets/img/profile2.png")}
              alt="Profile"
              className="profile-img"
              style={{ width: 100, height: 100, margin: "auto" }}
            />
          </div>
          {/* <div className="iconText">
            <p>Parametrage des mesures </p>
          </div> */}

          <div className="d-flex flex-column ">
            {mesures.map((mesure, index) => (
              <div
                key={index}
                className="row d-flex justify-content-between mesure-input mb-4"
              >
                <div className="col-10">
                  <input
                    type="text"
                    placeholder={`nom du mesure ${index + 1}`}
                    value={mesure}
                    onChange={(e) => {
                      const updatedMesures = [...mesures];
                      updatedMesures[index] = e.target.value;
                      setMesures(updatedMesures);
                    }}
                    className="form-control mr-2"
                  />
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    onClick={() => supprimerMesure(index)}
                    className="btn btn-danger"
                  >
                    <FiMinus />
                  </button>
                </div>
              </div>
            ))}

            <div className="d-flex justify-content-center mb-4">
              <button
                type="button"
                className="btn btn-danger mb-2"
                style={{ width: "46px" }}
                onClick={ajouterMesure}
              >
                <FiPlus />
              </button>
            </div>
            <div className="d-flex justify-content-center">
              {/* Le bouton est désactivé si tous les champs sont vides */}
              <button className="btn btn-danger" disabled={areAllInputsEmpty} onClick={validerMesures}>
                Valider les mesures
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Personaliser;
