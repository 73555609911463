import React, { useEffect, useState } from "react";
import "./css/commande.css";
import "../../utils/GlobalStyle.css";
import CommandeItemHome from "../../component/CommandeItemHome";
import { useDispatch, useSelector } from "react-redux";
import { useGetCommandesByUserQuery } from "../../redux/features/commandeFeature";
import { BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  ADD_COMMANDE_PATH,
  NOTIFICATION_PATH,
  PROFIL_PATH,
} from "../../routes/path/navigation_path";
import moment from "moment/moment";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Fab } from "@mui/material";
import ButtonAjout from "../../utils/Material-ui/buttonAjout";
import SearchAppBar from "../../utils/Material-ui/SearchInput";
import Header from "../../component/templateMobile/Header";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";

const Commandes = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const dispatch = useDispatch();
  const [filteredCommandes, setFilteredCommandes] = useState([]);

  const {
    data: datas,
    isLoading,
    isError,
  } = useGetCommandesByUserQuery(currentUser?.id);
  console.log({datas});

  const commandesLivraisonAujourdhui = useSelector(
    (state) => state.commandesSlice.commandesLivraisonAujourdhui
  );
  const commandesPasses = useSelector(
    (state) => state.commandesSlice.commandesPasses
  );
  const [searchTerm, setSearchTerm] = useState("");

  // Fonction pour trier les commandes par date du plus récent au plus ancien
  const sortCommandesByDate = (commandes) => {
    // Assurez-vous que la liste de commandes est définie avant de la trier
    if (!commandes || !Array.isArray(commandes)) {
      return [];
    }

    // Triez les commandes par date du plus récent au plus ancien
    return commandes.slice().sort((a, b) => new Date(b.date_livraison) - new Date(a.date_livraison));
  };

  // Fonction pour filtrer les commandes en fonction du terme de recherche
  const searchCommandes = (term) => {
    if (!term) {
      // Si le terme de recherche est vide, afficher toutes les commandes
      updateFilteredCommandes("tout");
      return;
    }

    const filtered = datas?.data?.filter((commande) => {
      // Vous pouvez personnaliser ici les champs sur lesquels vous souhaitez effectuer la recherche
      // Dans l'exemple ci-dessous, je suppose que vous voulez rechercher par le nom du client
      return (
        commande.client?.nom_complet.toLowerCase().includes(term.toLowerCase())
      );
    });

    setFilteredCommandes(filtered);
  };

  // Gestion du changement dans le terme de recherche
  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    searchCommandes(term);
  };

  // Fonction pour mettre à jour filteredCommandes
  const updateFilteredCommandes = (selectedStatus) => {
    let updatedFilteredCommandes;

    if (selectedStatus === "tout") {
      // Afficher toutes les commandes triées par date
      updatedFilteredCommandes = sortCommandesByDate(datas?.data);
    } else {
      // Afficher les commandes filtrées par statut triées par date
      updatedFilteredCommandes = sortCommandesByDate(datas?.data?.filter(commande => commande.statut === selectedStatus));
    }

    setFilteredCommandes(updatedFilteredCommandes);
  };
  // Au montage du composant, chargez toutes les commandes initialement
  useEffect(() => {
    updateFilteredCommandes("tout");
  }, [datas]);

  const firstLastName = currentUser?.firstName?.charAt(0) + currentUser?.lastName?.charAt(0) || "";
  const leftContent = (
    <Link to={PROFIL_PATH}>
      <div style={{ display: 'flex',  flexDirection: 'column', alignItems: 'center' }}>
      <img
        src={require("../../assets/img/profile2.png")}
        alt="Profile"
        className="profile-img"
        style={{ width: 55, height: 55, marginTop: "-4px" }}
      />
      <span style={{marginTop: "-18px", color:"#B9B8B8"}}>{firstLastName}</span>
      </div>
    </Link>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Commandes</span>
    </div>
  );

  const rightContent = (
    <div>
      <Badge
        badgeContent={ (Number(commandesPasses) || 0) + (Number(commandesLivraisonAujourdhui) || 0)}
        color="success"
      >
        <NotificationsNoneIcon
          style={{ fontSize: "35px", color: "#8E8EA1" }}
          onClick={() => navigate(NOTIFICATION_PATH)}
        />
      </Badge>
    </div>
  );

  return (
    <Layout leftContent={leftContent} centerContent={centerContent} rightContent={rightContent}>
    <div className="home-screen">
      <div className="commande-list">
        <span className="title-en-tete" style={{ color: "#000", margin: "5px" }}>
          Liste des commandes
        </span>

        <select
          onChange={(e) => updateFilteredCommandes(e.target.value)}
          className="seletedStatus"
          style={{padding:"9px"}}
        >
          <option value="tout">Tout</option>
          <option value="nouvelle">Nouvelle</option>
          <option value="encours">En confection</option>
          <option value="terminee">Terminée</option>
          <option value="livree">Livrée</option>
        </select>
        <ButtonAjout onClick={() => navigate(ADD_COMMANDE_PATH)} />

        <SearchAppBar onSearch={handleSearch} searchTerm={searchTerm}/>
        {isLoading ? (
          <SkeletonComponent />
        ) : isError ? (
          <p
            style={{
              marginTop: " 100px",
              color: "red",
              padding:"30px"
            }}
          >
            Une erreur s'est produite lors du chargement des données.
          </p>
        ) : filteredCommandes && filteredCommandes.length > 0 ? (
          filteredCommandes?.map((item, index) => (
            <Link
              key={index}
              to={`/detail-commande/${item?.id}`}
              style={{ textDecoration: "none" }}
            >
              
              <CommandeItemHome
                key={index}
                name={item?.client?.nom_complet}
                date={moment(item?.date_livraison).format("DD/MM/YYYY HH:mm")}
                status={item?.statut}
                montant={item?.montant}
                montant_paye={item?.montant_paye}
                montant_restant={item?.montant_restant}
              />
            </Link>
          ))
        ) : (
          <p
            className=""
            style={{
              marginTop: " 100px",
                  color: "red",
                  padding:"30px"
            }}
          >
            Pas de commandes disponibles 
          </p>
        )}

      </div>
      <br></br>
      <br></br>
    </div>
    </Layout>
  );
};

export default Commandes;

