import React from 'react';

const Header = ({ leftContent, centerContent, rightContent }) => {
  const headerStyle = {
    maxWidth: '500px',
    height: '65px',
    flexShrink: 0,
    boxShadow: '0px 4.032px 30.24px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    position: 'fixed',
    width: '100%', 
    top: '0',
    zIndex: 100,
  };

  return (
    <header style={headerStyle}>
      <div style={{marginTop: "6px"}}>{leftContent}</div>
      <div>{centerContent}</div>
      <div style={{marginTop: "6px"}}>{rightContent}</div>
    </header>
  );
};

export default Header;
