import React from "react";
import { Link } from "react-router-dom";
import "../utils/GlobalStyle.css";
import PersonPinIcon from '@mui/icons-material/PersonPin';


const clientCart ={
    display: 'flex',
    alignItems: 'center',
    justifycontent: 'space-between',
    margin: '5px',
    boxShadow: '0px 4.032px 30.24px 0px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#FFFFFF', /* Retirez les guillemets doubles ici */
    padding: '15px',
    borderRadius: '8px',
    marginTop: '10px',
    color:"#000000",
    border: "1px solid rgba(0, 0, 0, 0.05)"

    
}
const CardCompnent = ({
  clientName,
  ClientShortName,
  clientDetail,
  telephone,
}) => {
  // const firstLetter = clientName.charAt(0);

  return (
          <div className="d-flex justify-content-between" style={clientCart}>
            <div className="d-flex">
              <PersonPinIcon style={{fontSize:'40px', color: "#8E8EA1", marginTop:'1px', marginRight:"5px"}}/>
              <div>
                <div className="big-title ">{clientName}</div>
                <div className="litle-title">{telephone}</div>
              </div>
            </div>
            <div></div>
          </div>
  );
};

export default CardCompnent;
