import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN_PATH } from "../../routes/path/navigation_path";
import "./auth.css";
import { useForm } from "react-hook-form";
import {
  useLoginMutation,
  useRegisterUserMutation,
} from "../../redux/features/userFeature";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/img/tayeur-logo1.png";

const Register = () => {
  const [registerUser] = useRegisterUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const handleLogin = async (data) => {
    setIsLoading(true);
    try {
      const firstName = data.firstName;
      const lastName = data.lastName;
      const adresse = data.adresse;
      const phone = data.phone;
      const password = data.password;
      const body = JSON.stringify({
        firstName,
        lastName,
        adresse,
        phone,
        password,
      });
      const response = await registerUser(body);
      console.log(response);
      if (response?.error?.status === 400) {
        setError("phone", { message: "Ce numéro a déja un compte" });
      } else {
        // console.log(response)
        toast.success("Inscription reuissi !");
        navigate(LOGIN_PATH);
      }
    } catch (error) {
      console.log(error);
      // Handle any error that occurred during the login
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  return (
    <div className="container-fluid" style={{maxWidth:"500px", margin:'auto', marginTop:"50px"}}>
      <div className="row">
        <ToastContainer />
        <div className="col-lg-12 col-md-12 form-container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-box text-center">
            <div className="logo mt-5 mb-3">
              <img src={logo} alt="Image d'illustration" />
            </div>
            {/* <div className="heading mb-3">
              <h3>Connecter a son compte tayeur</h3>
            </div> */}
            <form onSubmit={handleSubmit(handleLogin)}>
              <div className="form-input">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                style={{backgroundColor:"#D9D9D9"}}
                  // onChange={(e) => setPhone(e.target.value)}
                  {...register("firstName", {
                    required: "Ce champ est obligatore!",
                  })}
                  type="text"
                  placeholder="Nom"
                />
              </div>
              {errors.firstName && (
                <p className="text-danger mb-4">{errors?.firstName?.message}</p>
              )}
              <div className="form-input">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                style={{backgroundColor:"#D9D9D9"}}
                  // onChange={(e) => setPhone(e.target.value)}
                  {...register("lastName", {
                    required: "Ce champ est obligatore!",
                  })}
                  type="text"
                  placeholder="Prenom"
                />
              </div>
              {errors.lastName && (
                <p className="text-danger mb-4">{errors?.lastName?.message}</p>
              )}
              <div className="form-input">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                style={{backgroundColor:"#D9D9D9"}}
                  // onChange={(e) => setPhone(e.target.value)}
                  {...register("phone", {
                    required: "Ce champ est obligatore!",
                  })}
                  type="tel"
                  placeholder="Telephone"
                />
              </div>
              {errors.phone && (
                <p className="text-danger mb-4">{errors?.phone?.message}</p>
              )}
              <div className="form-input">
                <span>
                  <i className="fa fa-envelope"></i>
                </span>
                <input
                style={{backgroundColor:"#D9D9D9"}}
                  // onChange={(e)a=> setPhone(e.target.value)}
                  {...register("adresse", {
                    required: "Ce champ est obligatore!",
                  })}
                  type="text"
                  placeholder="Adresse"
                />
              </div>
              {errors.adresse && (
                <p className="text-danger mb-4">{errors?.adresse?.message}</p>
              )}

              <div className="form-input">
                <span>
                  <i className="fa fa-lock"></i>
                </span>
                <input
                style={{backgroundColor:"#D9D9D9"}}
                  {...register("password", {
                    required: "Ce champ est obligatore!",
                  })}
                  type="password"
                  placeholder="********"
                />
              </div>
              {errors.password && (
                <p className="text-danger mb-4">{errors?.password?.message}</p>
              )}

              <div className="text-left mb-3">
                <button type="submit" className="btn" style={{padding:'13px'}}>
                  {isLoading ? (
                    <div
                      className="spinner-border text-light"
                      role="status"
                    ></div>
                  ) : (
                    "S'inscrire"
                  )}
                </button>
              </div>
              <Link style={{color:'black'}} to={LOGIN_PATH}>Connectez-vous</Link>
            </form>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 d-none d-md-block image-container"></div>
      </div>
    </div>
  );
};

export default Register;
