
import { ADD_COMPTABILITE_API_ROUTE } from "../../routes/api_routes";
import { ApiMananger } from "../apiManager";

const transactionApi = ApiMananger.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    addTransaction: build.mutation({
      query({body}) {
        return {
          url: ADD_COMPTABILITE_API_ROUTE,
          method: "POST",
          headers: {
            Accept: "application/json",
            // "Content-Type": "multipart/form-data",
          },
          body,
        };
      },
      invalidatesTags: ["Transactions"],
    }),
  
   
  }),
});

export const {
  
  useAddTransactionMutation,
 
} = transactionApi;
