import React, { useState, useEffect } from "react";
import { useGetCommandeQuery, useUpdateCommandeMutation } from "../../../redux/features/commandeFeature";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import '../css/updateCommande.css';

const styliser={
  bacgroundColor:'red',
}
const EditStatutCommande = ({ commandeStatut }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedStatut, setSelectedStatut] = useState(commandeStatut);
  const [updateCommande, {isLoading}] = useUpdateCommandeMutation();
  const { id: commandeId } = useParams();

  const { data: commande } = useGetCommandeQuery(commandeId);

  const { register, handleSubmit } = useForm();

  const handleStatutUpdate = () => {
    setIsUpdating(true);

    try {
      const newCommande = {
        statut: selectedStatut,
      };
      const formData = new FormData();

    formData.append("statut", selectedStatut);
    
      updateCommande({ id: commande?.id, body:  formData})
        .unwrap()
        .then((res) => {
          toast.success("Statut de la commande modifié avec succès !");
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de la commande", error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    // When the selectedStatut changes, automatically trigger the update
    handleStatutUpdate();
  }, [selectedStatut]);

  return (
    <>
    <ToastContainer />
      <form onSubmit={handleSubmit(handleStatutUpdate)} className="editCommande" >
        <select
        style={{
          boxShadow:'0px 3.13043px 29.73913px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '5px',
        backgroundColor: "#E72929",
        fontSize: "16px",
        color:'#fff',
        border:'none',
        padding:'2px'
      }}
          value={selectedStatut}
          onChange={(e) => setSelectedStatut(e.target.value)}
          disabled={isLoading}
          className="form-control ms-3"

        >
          {isLoading ? (
                  <option className="spinner-border text-light" role="status"></option>
                ):(

                 <>
                  <option value="nouvelle">Nouvelle</option>
                  <option value="encours">En Cours</option>
                  <option value="terminee">Terminée</option>
                  <option value="livree">Livrée</option>
                 </>
                )
              }
        </select>
      </form>
    </>
  );
};

export default EditStatutCommande;

