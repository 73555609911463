import React from 'react';
import TextField from '@mui/material/TextField';

const CustomInput = ({ label, placeholder, value, onChange, name, id, register, text, Texvalue, error, type }) => {
  return (
    <TextField
      //  error
      //  helperText="Incorrect entry."
      type={type}
      label={label}
      name={name}
      id={id}
      placeholder={placeholder}
      value={value}
      {...register}
      onChange={onChange}
      size="small"
      fullWidth sx={{ m: 1 }}
      text={name}
      // variant="filled"
      
      
     
      // inputRef={ref} // Passez le ref à l'élément TextField
    />
  );
};

export default CustomInput;



// import React from 'react';
// import TextField from '@mui/material/TextField';

// const CustomInput = ({ label, placeholder, value, onChange, name, id, defaultValue }) => {
//   return (
//     <TextField
//       label={label} 
//       name={name}
//       id={id}
//       placeholder={placeholder}
//       value={value}
//       defaultValue={defaultValue}
//       onChange={onChange}
//       size="small"
//       style={{ width: '300px'}}
//     />
//   );
// };

// export default CustomInput;
