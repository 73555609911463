import React, { useState } from "react";
import { useAddTransactionMutation } from "../../../redux/features/transaction";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DETAIL_COMMANDE_PATH } from "../../../routes/path/navigation_path";

const button = {
  backgroundColor: "#E82828",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  fontFamily: "Montserrat",
  color: "white",
};

const ModalPaiement = ({ onShowModal, commandeId, setIsModalOpen  }) => {
    const [addTransaction] = useAddTransactionMutation();
    const [isModalOpen, setIsModalOpenLocal] = useState(false);
    const navigate = useNavigate();

    const { id } = useParams();
    // console.log({id});
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const [sePaie, setPaie] = useState("");

  
const handleSaveChanges = async () => {
  try {
    const montantPaye = parseInt(sePaie);
    if (isNaN(montantPaye) || montantPaye < 0) {
      toast.error("Le montant du paiement doit être un nombre positif.");
      return;
    }

    const newTransaction = {
      createdBy: currentUser?.id,
      commande: commandeId,
      montant_paye: montantPaye,
    };

    const result = await addTransaction({ body: newTransaction })
      .unwrap()
      .then((reponse) => {
        console.log(reponse);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log('Transaction Response:', result);
    toast.success("Montant mis à jour avec succès!");
    // navigate(DETAIL_COMMANDE_PATH);
    setIsModalOpenLocal(false); // Utilisez la variable locale pour fermer le modal

    handleModalHide();
  } catch (error) {
    console.error(
      "Erreur lors de la mise à jour du statut de la commande",
      error
    );
    toast.error("Erreur lors de la mise à jour du montant payé.");
  }
};

const handleModalShow = () => {
  setPaie(""); // Clear the input field when the modal is opened
  setIsModalOpenLocal(true); // Utilisez la variable locale pour ouvrir le modal
};

const handleModalHide = () => {
  setIsModalOpenLocal(false); // Utilisez la variable locale pour fermer le modal
};


  return (
    <>
      <button
        type="button"
        className="btn"
        style={button}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        onClick={handleModalShow}
      >
        Ajouter une paiement
      </button>

      <div
         className={`modal fade ${isModalOpen ? "show" : ""}`}
         id="exampleModal"
         tabIndex="-1"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Ajout avance
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalHide}
              ></button>
            </div>
            <div className="modal-body">
              <form encType="multipart/form-data">
                <input
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid",
                    padding: "5px",
                  }}
                  type="number"
                  className="form-control"
                  placeholder="Montant du paiement"
                  onChange={(e) => setPaie(e.target.value)}
                />
              </form>
            </div>
            <div className="m-auto mb-2">
              <button type="button" onClick={handleSaveChanges} className="btn" style={button}>
              Enregistrer 
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalPaiement;
