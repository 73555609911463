import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_API } from "../routes/api_routes";



export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
      baseUrl: DEFAULT_API,
    }),
    refetchOnReconnect: true,
    keepUnusedDataFor: 86400,
    reducerPath: "tayeur-gestion",
    endpoints: () => ({}),
  });


  export const ApiMananger = baseApi.enhanceEndpoints({
    addTagTypes: [
      "User",
      "Client",
      "Commande",
    ],
  });