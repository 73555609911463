import React, { useState, useEffect } from "react";
import { useGetCommandeQuery, useUpdateCommandeMutation } from "../../../redux/features/commandeFeature";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import './css.css';
import '../../../utils/GlobalStyle.css'
import Layout from "../../../component/templateMobile/TemplateMobile";
import { IoIosArrowBack } from "react-icons/io";
import { format } from 'date-fns';

const Historique = () => {
  const {id} = useParams(); 
  const navigate = useNavigate();

  const { data: commande, isLoading } = useGetCommandeQuery(id);

  console.log({commande});
    
  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Historique</span>
    </div>
  );
  const rightContent = null;

  // Afficher la liste des transactions
  const renderTransactions = () => {
    if (commande && commande?.transactions && commande?.transactions?.length > 0) {
        let totalMontantPaye = 0;
      return (
        <div className="mx-2">
          <p className="mx-2"><span className="big-title">Nom du client</span> : {commande.client?.nom_complet}</p>
          <p className="mx-2"><span className="big-title">Prix de confection</span> : {commande.montant} Fcfa</p>
          <p className="mx-2"><span className="big-title">Montant restant</span> : {commande.montant_restant} Fcfa</p>

            {commande.transactions.map((transaction) => {
                  totalMontantPaye += transaction.montant_paye;
                 return (
                    <div className="histo" key={transaction.id}>
                      <div className="d-block">
                        <p>Date de transaction :</p>
                        <p>{format(new Date(transaction.date_transaction), 'dd/MM/yyyy HH:mm:ss')}</p>
                      </div>
                      <div className="d-block">
                        <p>Montant avancé : {transaction.montant_paye} Fcfa</p>
                      </div>
                    </div>
                  )
                 })}
             <div className="total-montant-paye">
            <p className="mx-2">Total Montant Payé : {totalMontantPaye} Fcfa</p>
          </div>
        </div>
      );
    } else {
      return <p>Aucune transaction pour cette commande.</p>;
    }
  };
  return (
    <Layout
    leftContent={leftContent}
    centerContent={centerContent}
    rightContent={rightContent}
  >
    
    <div style={{ marginTop: "100px" }}>
        {/* Afficher la liste des transactions */}
        {renderTransactions()}
      </div>
  </Layout>
  );
};

export default Historique;

