import "./css/client.css";
import { useEffect, useState } from "react";
import {
  ADD_CLIENT_PATH,
  DETAIL_CLIENT_PATH,
  NOTIFICATION_PATH,
  PROFIL_PATH,
} from "../../routes/path/navigation_path";
import { useGetClientsQuery } from "../../redux/features/clientFeature";
import CardCompnent from "../../component/cardCompnent";
import { BsFillSearchHeartFill } from "react-icons/bs";
import React from "react";
import { useSelector } from "react-redux";
import { BsPlus } from "react-icons/bs";
import { useNavigate, Link, useParams } from "react-router-dom";
import ButtonAjout from "../../utils/Material-ui/buttonAjout";
import "../../utils/GlobalStyle.css";
import SearchAppBar from "../../utils/Material-ui/SearchInput";
import CssBaseline from "@mui/material/CssBaseline";
import { Badge, ThemeProvider } from "@mui/material";
import theme from "../../utils/Material-ui/theme";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Header from "../../component/templateMobile/Header";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";

const Clients = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state?.UserSlice?.info);

  const id = currentUser?.id;
  const { data, isLoading, error } = useGetClientsQuery(id);

  const commandesLivraisonAujourdhui = useSelector(
    (state) => state.commandesSlice.commandesLivraisonAujourdhui
  );
  const commandesPasses = useSelector(
    (state) => state.commandesSlice.commandesPasses
  );
// const clientId = useParams;
  const firstLastName =
    currentUser?.firstName?.charAt(0) + currentUser?.lastName?.charAt(0) || "";
  const leftContent = (
    <Link to={PROFIL_PATH}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={require("../../assets/img/profile2.png")}
          alt="Profile"
          className="profile-img"
          style={{ width: 55, height: 55, marginTop: "-4px" }}
        />
        <span style={{ marginTop: "-18px", color: "#B9B8B8" }}>
          {firstLastName}
        </span>
      </div>
    </Link>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Clients</span>
    </div>
  );

  const rightContent = (
    <div>
      <Badge
        badgeContent={
          (Number(commandesPasses) || 0) +
          (Number(commandesLivraisonAujourdhui) || 0)
        }
        color="success"
      >
        <NotificationsNoneIcon
          style={{ fontSize: "35px", color: "#8E8EA1" }}
          onClick={() => navigate(NOTIFICATION_PATH)}
        />
      </Badge>
    </div>
  );

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (data) {
      // Copiez les données pour éviter de modifier l'original.
      const dataCopy = [...data];
      dataCopy.sort((a, b) => a.nom_complet.localeCompare(b.nom_complet));
      setFilteredData(dataCopy);
    }
  }, [data]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    const filteredClients = data?.filter(
      (client) =>
        client?.nom_complet.toLowerCase().includes(term) ||
        client?.telephone.includes(term)
    );

    setFilteredData(filteredClients);
  };

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <ThemeProvider theme={theme}>
        <div className="home-screens">
          {isLoading ? ( // Affiche le Skeleton Loader lorsque isLoading est vrai
            <SkeletonComponent /> // Assurez-vous d'ajuster cela en fonction de vos besoins spécifiques
          ) : (
            <div>
              {/* Votre contenu réel */}
              <div className="commande-listst">
                <span className="title-en-tete">
                  Liste des clients
                </span>
                <SearchAppBar onSearch={handleSearch} searchTerm={searchTerm} />

                {filteredData.length > 0 ? (
                  filteredData.map((item, idx) => (
                    <Link
                      key={idx}
                      to={`/detail-client/${item?.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CardCompnent
                        key={idx}
                        clientName={item?.nom_complet}
                        telephone={item?.telephone}
                      />
                    </Link>
                  ))
                ) : (
                  <p
                    className=""
                    style={{
                      marginTop: " 50px",
                      color: " red",
                    }}
                  >
                    Aucun client ne correspond a votre recheche .
                  </p>
                )}
                          <ButtonAjout onClick={() => navigate(ADD_CLIENT_PATH)} />

              </div>
              <br></br>
              <br></br>
              <br></br>
            </div>
          )}
        </div>
      </ThemeProvider>
    </Layout>
  );
};

export default Clients;
