import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useGetClientByIdQuery } from "../../redux/features/clientFeature";
import ClientInfoComponent from "../../component/ClientInformationcomponent";
import MesureComponent from "../../component/MesureComponent";
import { BsCheck2Circle } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuPenLine } from "react-icons/lu";
import { IoIosArrowBack } from "react-icons/io";
import Header from "../../component/templateMobile/Header";
import {
  ADD_COMMANDE_ID_PATH,
  ADD_COMMANDE_PATH,
  CLIENTS_PATH,
  COMMANDES_PATH,
} from "../../routes/path/navigation_path";
import DeleteClient from "./DeleteClient";
import "../../utils/GlobalStyle.css";
import Layout from "../../component/templateMobile/TemplateMobile";
import "./css/modifClient.css";
import { FiUpload } from "react-icons/fi";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import {
  useAddCommandeMutation,
  useGetCommandesByUserQuery,
} from "../../redux/features/commandeFeature";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useAddTransactionMutation } from "../../redux/features/transaction";
import Select from "react-select";
import { addNewCommande } from "../../redux/slices/commandeSlice";
import { ToastContainer, toast } from "react-toastify";
import AddCommandeInClient from "./AddCommandeInClient";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const DetailClient = () => {
  let { id } = useParams();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { data } = useGetClientByIdQuery(id);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const {
    data: datas,
    Loading,
    isError,
  } = useGetCommandesByUserQuery(currentUser?.id);
  console.log({ datas });

  // avoir le montant à payé pour le client actuel
  const filtered = datas?.data?.filter((commande) => {
    return commande.clientId === Number(id);
  });
  const totalRestant = filtered?.reduce((acc, item) => acc + item.montant_restant, 0) || 0;

  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commandeIdToDelete, setCommandeIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleDeleteClient = (id) => {
    setCommandeIdToDelete(id);
    setShowDeleteModal(true);
  };

  const leftContent = (
    <Link to={CLIENTS_PATH}>
      <div style={{ cursor: "pointer", color: "black" }}>
        <IoIosArrowBack size={24} onClick={() => navigate(-1)} />
      </div>
    </Link>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Informations client</span>
    </div>
  );
  // ajouter commande modal
  const [errorFields, setErrorFields] = useState([]);
  // Image preview
  // eslint-disable-next-line no-unused-vars

  const [dateError, setDateError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [addCommande] = useAddCommandeMutation();
  const [addTransaction] = useAddTransactionMutation();

  const [formData, setFormData] = useState({
    nom: "",
    quantite: "",
  });

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const rightContent = (
    <div className="d-flex" style={{ color: "#E82828" }}>
      <RiDeleteBin6Line
        style={{ marginRight: "10px" }}
        onClick={() => handleDeleteClient(id)}
      />
      <LuPenLine onClick={() => navigate(`/clients/${id}`)} />
    </div>
  );

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 200); // Définissez le temps d'attente en millisecondes (ici, 2000 ms ou 2 secondes)

    return () => {
      clearTimeout(loaderTimeout); // Assurez-vous de nettoyer le timeout lors du démontage du composant
    };
  }, []);
  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <br></br>
      <br></br>
      {showLoader ? ( // Affiche le Skeleton Loader lorsque isLoading est vrai
        <SkeletonComponent /> // Assurez-vous d'ajuster cela en fonction de vos besoins spécifiques
      ) : (
        <div>
          {/* suppression */}
          <div className="container" style={{ marginTop: "-10px" }}>
            {/* <div className="row"> */}
            {/* <span className="sub-title">Informations client</span> */}

            <div className="">
              <ClientInfoComponent
                text="Nom Complet"
                Texvalue={data?.nom_complet}
              />
              <ClientInfoComponent
                text="Telephone"
                Texvalue={data?.telephone}
              />
              <ClientInfoComponent text="Adresse" Texvalue={data?.adresse} />

              <ClientInfoComponent text="Sexe" Texvalue={data?.sexe} />
              <ClientInfoComponent
                text="Pays"
                Texvalue={data?.pays ? data?.pays : "le pays n'est pas défini"}
              />
              <ClientInfoComponent
                text="Ville"
                Texvalue={
                  data?.Ville ? data?.Ville : "la ville nest pas  défini"
                }
              />
              <ClientInfoComponent
                text="Montant à payé"
                Texvalue={
                  totalRestant
                    ? totalRestant + " Fcfa"
                    : "montant non disponible"
                }
              />
               <Link
                      to={`/all_c_client/${id}`}
                      style={{ textDecoration: "none",color: "black" }}
                    >
                      <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
              <ClientInfoComponent
                text="Nombre commandes faites"
                // Texvalue={
                //   filtered?.length
                //     ? filtered?.length
                //     : "pas de commande pour ce client"
                // }
              />
              <span>{filtered?.length}</span>
              <RemoveRedEyeOutlinedIcon  style={{color:"#E82828"}} className="mx-2"/>
              </div>
              </Link>
            </div>
            <div style={{ textAlign: "center", marginTop: "5px" }}>
              <span className="sub-title">Mesures client</span>
            </div>
            {/* {data?.mesure_client && data?.mesure_client.length > 0 ? ( */}
            {data?.mesure_client && data?.mesure_client.length > 0 ? (
              data?.mesure_client.some(
                (item) => item?.nomMesure && item?.uniteMesure
              ) ? (
                data?.mesure_client.map((item, index) => (
                  <div key={index} className="col-md-12 col-12 colcommande my-2">
                    {item?.nomMesure && item?.uniteMesure ? (
                      <MesureComponent
                        text={`${item?.nomMesure} :`}
                        Texvalue={item?.uniteMesure}
                      />
                    ) : null}
                  </div>
                ))
              ) : (
                // Ce bloc est rendu si au moins un attribut nomMesure ou uniteMesure n'est pas défini
                <p>Aucune mesure valide pour ce client.</p>
              )
            ) : (
              // Ce bloc est rendu si les données (data) ou mesure_client n'est pas défini
              <p style={{color:"#E82828"}}>Aucune mesure pour ce client.</p>
            )}

            {showDeleteModal && (
              <DeleteClient
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                commandeIdToDelete={commandeIdToDelete}
                onDeleted={() => {
                  // Cette fonction sera appelée après la suppression de la commande
                  // Vous pouvez mettre à jour l'état ou effectuer d'autres actions ici
                }}
              />
            )}
          </div>
          <div style={{ textAlign: "center", marginTop: "5px" }}>
            <button
              className="validateButton mt-2"
              style={{ width: "51%", marginLeft: "16px" }}
              onClick={openModal}
            >
              Ajouter une commande
            </button>
            <AddCommandeInClient
              closeModal={closeModal}
              showModal={showModal}
              data={data}
            />
          </div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      )}
    </Layout>
  );
};

export default DetailClient;
