// Modal.js
import React, { useRef } from "react";
import ReactModal from "react-modal";
import {
  useGetCommandeQuery,
  useGetCommandesByUserQuery,
} from "../redux/features/commandeFeature";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import download from "../assets/img/fa_download.png";
import printer from "../assets/img/printer.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Modal = ({ isOpen, onClose, children }) => {
  const modalContentRef = useRef();
  const { id } = useParams();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { data: commande, isLoading } = useGetCommandeQuery(id);
  console.log('commande', commande);
  const {
    data: datas,
    Loading,
    isError,
  } = useGetCommandesByUserQuery(currentUser?.id);
  // console.log({ datas });

  const handlePrint = () => {
    const content = modalContentRef.current;
    const originalDocument = document.body.innerHTML;

    document.body.innerHTML = content.innerHTML;

    window.print();

    // Wait for the print process to finish
    window.addEventListener(
      "afterprint",
      () => {
        document.body.innerHTML = originalDocument;
        // window.location.reload();
      },
      { once: true }
    );
    window.location.reload();
  };

  // const currentUser = useSelector((state) => state?.UserSlice?.info);
  const downloadAsPDF = () => {
    if (!modalContentRef.current) return;
    const content = modalContentRef.current;

    // Set the scale factor for capturing the content
    const scaleFactor = 2;

    // Capture the content as an image
    html2canvas(content, { scale: scaleFactor }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // Set the width and height for displaying in PDF
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      // Add the image to the PDF with the correct dimensions
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Save the PDF
      pdf.save("modal_content.pdf");
    });
  };

  const filtered = datas?.data?.filter((commande) => {
    return commande.clientId === Number(id);
  });
  // const totalQuantity = filtered?.reduce((acc, cmd) => acc + cmd.quantity, 0);
  console.log({ commande });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          height: "850px",
        },
        content: {
          position: "absolute",
          top: "36%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: " 400px",

          borderRadius: "8px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      <div ref={modalContentRef}>
        {children}
        <div className="gerer-facture d-flex justify-content-between">
          <div className="nom_tayeur">
            <h6 className="tayeur">Tayeur </h6>
            <p className="tayeur_p">
              {currentUser?.firstName} {currentUser?.lastName}
            </p>
            <p className="tayeur_p">{currentUser?.adresse}</p>
            <p className="tayeur_p">Téléphone: {currentUser?.phone} </p>
          </div>
          <div className="info_client">
            <h6 className="tayeur">Client</h6>
            <p className="tayeur_p">{commande?.client?.nom_complet} </p>
            <p className="tayeur_p">Téléphone: {commande?.client?.telephone} </p>
            <p className="tayeur_p">{commande?.client?.ville} </p>
          </div>
        </div>

        <table className="table table-bordered mt-4">
          <thead>
            <tr className="table_tr">
              <th>Désignation</th>
              <th>Quantité</th>
              <th>Prix</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {/* Insérez ici les lignes du tableau avec les données dynamiques */}
            <tr>
              <td>{commande?.nom_tissu}</td>
              <td>{filtered?.length}</td>
              <td>{commande?.montant}</td>
              <td>{commande?.montant_paye}</td>
            </tr>
            {/* ... Autres lignes ... */}
          </tbody>
        </table>
        <div className="col-md-12  text-border prix">
          <p className="tayeur_p">
            Montant de la commande: {commande?.montant} Fcfa
          </p>
          <p className="tayeur_p">
            Montant payé: {commande?.montant_paye} Fcfa
          </p>
          <p className="tayeur_p">restant: {commande?.montant_restant} Fcfa</p>
        </div>
      </div>
      <div className="footer d-flex justify-content-center ">
        <button className="btn btn-danger" onClick={downloadAsPDF}>
          <img src={download} />
          &nbsp;Télécharger
        </button>
        <button className=" btn-gray" onClick={handlePrint}>
          <img src={printer} />
          &nbsp;Imprimer
        </button>
      </div>
    </ReactModal>
  );
};

export default Modal;
