import React, { useEffect, useState } from "react";
import { FaCalendarCheck } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import {
  useAddCommandeMutation,
  useGetCommandeQuery,
  useUpdateCommandeMutation,
} from "../../redux/features/commandeFeature";
import "./css/addCommande.css";
import "../../utils/GlobalStyle.css";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { COMMANDES_PATH } from "../../routes/path/navigation_path";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import {
  useGetClientsQuery,
  useUpdateClientMutation,
} from "../../redux/features/clientFeature";
import { FiPlus, FiUpload } from "react-icons/fi";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import { DEFAULT_API_IMAGE } from "../../routes/api_routes";
import "./css/updateCommande.css";
import CustomInput from "../../component/Input";
import Layout from "../../component/templateMobile/TemplateMobile";
import { useAddTransactionMutation } from "../../redux/features/transaction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UpdateCommande = () => {
  const { id } = useParams();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  // console.log(id);
  // const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [updateCommande, { isLoading }] = useUpdateCommandeMutation();
  const { data: currentCommande, isLoading: currentCommandeLoading } =
    useGetCommandeQuery(id);
  // console.log("mmm", commandeMutation);

  const montantPaye = currentCommande?.transactions?.map((item) => {
    return parseInt(item?.montant_paye);
  });

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      clientId: currentCommande?.clientId,
      nom_tissu: currentCommande?.nom_tissu,
      metre_tissu: currentCommande?.metre_tissu,
      montant: currentCommande?.montant,
      date_livraison: selectedDate,
      montant_paye: montantPaye,
    },
  });
  console.log(
    "currentCommande?.montant_restant",
    currentCommande?.montant_restant
  );
  //les variables
  const [montantAvance, setMontantAvance] = useState(
    currentCommande?.montant_paye || 0
  );
  const [montantRestant, setMontantRestant] = useState(
    currentCommande?.montant_restant || 0
  );
  const [montantPayeFromApiCompta, setMontantPayeFromApiCompta] = useState();
  const [montantState] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [errorState, setErrorState] = useState(false);
  const [isInvalidPrice, setIsInvalidPrice] = useState(false);
  const [isInvalidAvance, setIsInvalidAvance] = useState(false);
  const { data, error } = useGetClientsQuery(currentUser?.id);
  const [addTransaction] = useAddTransactionMutation();

  //verification des champs
  const validatePrice = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };
  const validateAvance = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };
  const handleAvance = (e) => {
    const inputValue = parseInt(e.target.value);
    const newMontantRestant = currentCommande?.montant - inputValue;

    console.log();
    // Vérifier si montantPaye dépasse le montant total
    if (inputValue <= currentCommande?.montant) {
      setMontantPayeFromApiCompta(inputValue);
      setMontantRestant(newMontantRestant >= 0 ? newMontantRestant : 0);
      setIsInvalidAvance(false);
    } else {
      // Afficher une erreur lorsque montantPaye dépasse le montant total
      setMontantPayeFromApiCompta(montantPayeFromApiCompta);
      setMontantRestant(montantRestant);
      setIsInvalidAvance(true);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const updatedMontantRestant = currentCommande?.montant - montantAvance;
    setMontantRestant(updatedMontantRestant);

    const date_livraison = new Date(currentCommande?.date_livraison);
    const formattedDateLivraison = `${date_livraison.getFullYear()}-${(
      date_livraison.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${date_livraison
      .getDate()
      .toString()
      .padStart(2, "0")}`;
    setValue("date_livraison", formattedDateLivraison);
  }, [currentCommande, montantAvance, setValue]);

  //   console.log(transactionIds)
  const submit = (data) => {
    if (currentCommande?.statut === "livree") {
      // You can show a message or take any other action to indicate that modification is not allowed
      toast.warning("Modification not allowed for delivered commands.");
      return;
    }
console.log(data.montant_paye);
console.log(currentCommande?.montant_restant);
      // Vérification du montant payé ici
  const newMontantPaye = parseInt(data?.montant_paye);
  if (newMontantPaye > currentCommande?.montant_restant) {
    // Afficher une erreur ou effectuer toute autre action nécessaire
    toast.error("Le montant de l'avance ne peut pas dépasser le montant restant.");
    return;
  }
    if (data.modele && data.modele[0]) {
      const formData = new FormData();
      const dateLivraison = new Date(data.date_livraison);
      const isoDate = dateLivraison.toISOString();
      formData.append("clientId", parseInt(data.clientId));
      formData.append("nom_tissu", data.nom_tissu);
      formData.append("metre_tissu", parseInt(data.metre_tissu));
      formData.append("montant", parseInt(data.montant));
      formData.append("montant_paye", montantPayeFromApiCompta);
      formData.append("date_livraison", isoDate);
      formData.append("modele", data.modele[0]);
      formData.append("createdBy", currentUser.id);
      // console.log("donne", { data });
      // Call the addCommande mutation with the FormData object
      updateCommande({ id, body: formData })
        .unwrap()
        .then((response) => {
          const newTransaction = {
            createdBy: response?.createdBy,
            commande: response.id,
            montant_paye: parseInt(montantPayeFromApiCompta),
          };
          addTransaction(newTransaction)
          .then((res) => {
            setMontantPayeFromApiCompta(res.montant_paye);
            console.log("Commande modfiée avec succès !", res);
          })
          .catch(() => {
              toast.error("Erreur lors de la modfication de la commande");
            });
          console.log("Commande modfiée avec succès !");
          toast.success("Ajout commande succès !"); // Utilisez toast pour l'alerte de succès
          setTimeout(() => {
            navigate(COMMANDES_PATH);
          }, 1000);
        })
        .catch((error) => {
          // Handle errors, e.g., display an error message
          console.error("Error adding commande:", error);
        });
    } else {
      const new_data = new FormData();
      const dateLivraison = new Date(data.date_livraison);
      const isoDate = dateLivraison.toISOString();
      new_data.append("clientId", parseInt(data.clientId));
      new_data.append("nom_tissu", data.nom_tissu);
      new_data.append("metre_tissu", parseInt(data.metre_tissu));
      new_data.append("montant", parseInt(data.montant));
      new_data.append("montant_paye", montantPayeFromApiCompta);
      new_data.append("date_livraison", isoDate);
      new_data.append("createdBy", currentUser.id);
      updateCommande({ id, body: new_data })
        .unwrap()
        .then((response) => {
          const newTransaction = {
            createdBy: response?.createdBy,
            commande: response.id,
            montant_paye: parseInt(montantPayeFromApiCompta),
          };
          // console.log(newTransaction)
          addTransaction(newTransaction)
            .then((res) => {
              setMontantPayeFromApiCompta(res?.montant_paye);
            })
            .catch(() => {
              toast.error("Erreur lors de l'ajout de la commande");
            });
          toast.success("Modfication commande succès !"); // Utilisez toast pour l'alerte de succès

          setTimeout(() => {
            navigate(-1);
          }, 1000);
        })
        .catch((error) => {
          // Handle errors, e.g., display an error message
          console.error("Error adding commande:", error);
        });
    }
  };

  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );

  const centerContent = (
    <div className="titre-header">
      <span>Modification commande</span>
    </div>
  );

  const optionClient = data?.map((item) => ({
    value: item.id,
    label: item.nom_complet,
  }));
  const [selectedClient, setSelectedClient] = useState(null);

  // Lorsque le composant est monté, définissez selectedClient sur le client existant
  useEffect(() => {
    if (currentCommande?.clientId) {
      const initialSelectedClient = optionClient.find(
        (client) => client.value === currentCommande?.clientId
      );
      setSelectedClient(initialSelectedClient);
    } else {
      setSelectedClient(null);
    }
  }, [currentCommandeLoading]);

  // disabled={currentCommande?.statut === "livree"}
  return (
    <Layout leftContent={leftContent} centerContent={centerContent}>
      <div className="container ajout-client">
        <ToastContainer />
        {/* ___commande___ */}
        <div className="commande-container">
          <div className="form-content border  p-3 shadow">
            <div className="card-input"></div>
            <form onSubmit={handleSubmit(submit)} encType="multipart/form-data" disabled={currentCommande?.statut === "livree"}>
              <div className="add-commande-container">
                <div className="add-commande-client-section">
                  {/* Client */}
                  <div className="row section-content">
                    <div className="row m-2">
                      <div className="input-group">
                        <label htmlFor="clientId">Nom client</label>
                        {currentCommande?.statut !== "livree" ? (
                          <Select
                            className="onSmallDevicce"
                            name="clientId"
                            id="clientId"
                            options={optionClient}
                            placeholder="Choisissez un client"
                            style={{ width: "330px" }}
                            isClearable={true}
                            {...register("clientId")}
                            value={selectedClient || null}
                            onChange={(client) =>
                              client?.value !== "+" && setSelectedClient(client)
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "300px", // Personnalisez la largeur ici
                              }),
                            }}
                          />
                        ) : (
                          <Select
                            className="onSmallDevicce"
                            name="clientId"
                            id="clientId"
                            options={optionClient}
                            placeholder="Choisissez un client"
                            style={{ width: "330px" }}
                            isClearable={true}
                            isDisabled // This makes the Select disabled
                            value={selectedClient || null}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "300px", // Personnalisez la largeur ici
                              }),
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Commandes */}
                  <div className="row section-content ">
                    <div className="row m-2">
                      <div className="col-xl-5 col-lg-7">
                        <label htmlFor="nom_tissus">Nom du tissu</label>
                        <input
                          type="text"
                          name="nom_tissu"
                          id="nom_tissu"
                          className="form-control ml-2"
                          {...register("nom_tissu", {
                            required: "Champ obligatoire!",
                          })}
                          readOnly={currentCommande?.statut === "livree"}
                        />
                      </div>
                    </div>

                    <div className="row m-2">
                      <div className="col-xl-5 col-lg-7">
                        <label htmlFor="quantite">Unité</label>
                        <input
                          type="number"
                          name="metre_tissu"
                          id="metre_tissu"
                          className="form-control"
                          {...register("metre_tissu", {
                            required: "Champ obligatoire!",
                          })}
                          readOnly={currentCommande?.statut === "livree"}
                        />
                      </div>
                    </div>
                    <div className="row section-content">
                    <div className="row m-2">
                      <div className="modeleImage col-xl-5 col-lg-7">
                        <label htmlFor="modele">Modèle</label>
                        <button className="modeleLabel">
                          <FiUpload /> Importer une image
                        </button>
                        {previewImage && (
                          <img
                            src={previewImage}
                            alt="modele"
                            style={{
                              marginTop: "5px",
                              width: "350px",
                              height: "200px",
                            }}
                          />
                        )}
                        {currentCommande?.modele ? (
                          <img
                            src={
                              DEFAULT_API_IMAGE + `${currentCommande.modele}`
                            }
                            alt="modele"
                            style={{
                              marginTop: "5px",
                              width: "350px",
                              height: "200px",
                            }}
                          />
                        ) : null}
                        <input
                          type="file"
                          name="modele"
                          id="modele"
                          className="form-control custom-file-input"
                          {...register("modele")}
                          accept="image/*"
                          onChange={handleFileUpload}
                          disabled={currentCommande?.statut === "livree"}
                        />
                        {/* {
                      currentCommande?.modele ? <img src={DEFAULT_API_IMAGE + `${currentCommande?.modele}`} alt="modele" style={{ marginTop: '5px', width: '200px', height: '200px' }} />:''
                    } */}
                      </div>
                    </div>
                    </div>
                   
                    <div className="row m-2">
                      <div className="col-xl-5 col-lg-7">
                        <label htmlFor="date_livraison">
                          Date de livraison
                        </label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => {
                            setValue("date_livraison", date, {
                              shouldValidate: true,
                            });
                            setSelectedDate(date);
                          }}
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          readOnly={currentCommande?.statut === "livree"}
                        />
                        {/* )}
                        /> */}
                      </div>
                    </div>
                    </div>

                  {/* Paiement */}
                  {/* <div className="row section-content"> */}
                  {/* </div> */}
                <div className="row section-content">
                  <div className="row m-2">
                    <div className="col-xl-5 col-lg-7">
                      <label htmlFor="prix">Prix confection</label>
                      <input
                        type="number"
                        name="montant"
                        id="montant"
                        className="form-control"
                        {...register("montant", {
                          required: "Champ obligatoire!",
                        })}
                        readOnly={currentCommande?.statut === "livree" || currentCommande?.montant_restant === 0 }
                      />
                    </div>
                  </div>
                    <div className="row m-2">
                        <label htmlFor="montant_avance">
                          ajouter une avance
                        </label>
                      <div className="col-xl-5 col-lg-7">
                        <input
                          type="number"
                          name="montantAvance"
                          id="montantAvance"
                          className={`form-control ${
                            isInvalidAvance ? "is-invalid" : ""
                          }`}
                          {...register("montant_paye")}
                          readOnly={currentCommande?.montant_restant === 0}
                          onChange={(e) => {
                            const inputValue = parseInt(e.target.value);
                            const newMontantPaye = inputValue >= 0 ? inputValue : 0;
                            const newMontantRestant = currentCommande?.montant_restant - newMontantPaye;
                          
                            // console.log("newMontantPaye:", newMontantPaye);
                            // console.log("newMontantRestant:", newMontantRestant);
                            // console.log("currentCommande?.montant_restant:", currentCommande?.montant_restant);
                          
                            // Vérifier si montantPaye dépasse le montant total
                            if (newMontantPaye <= newMontantRestant) {
                              setMontantPayeFromApiCompta(newMontantPaye);
                              setMontantRestant(newMontantRestant >= 0 ? newMontantRestant : 0);
                              setIsInvalidAvance(false);
                            } else {
                              // Afficher une erreur lorsque montantPaye dépasse le montant total
                              setMontantPayeFromApiCompta(newMontantPaye);
                              setMontantRestant(newMontantRestant);
                              setIsInvalidAvance(true);
                            }
                            handleAvance(e);
                          }}
                          
                        />

                        {isInvalidAvance && (
                          <p className="error-msg">
                            &nbsp; Le montant de l'avance ne peut pas dépasser
                            le montant restant.
                          </p>
                        )}

                        {currentCommande?.montant_restant === 0 && (
                          <p className="error-msg">
                            &nbsp; Aucun montant restant. La commande est
                            entièrement payée.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-3">
                    <button
                      style={{
                        backgroundColor: "#E82828",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                        fontWeight: "600",
                        fontSize: "14px",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        padding: "10px 30px",
                        border: "none",
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                      }}
                      disabled={isLoading}
                      type="submit"
                    >
                      {isLoading ? (
                        <div
                          className="spinner-border text-light"
                          role="status"
                        ></div>
                      ) : (
                        "Modifier"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* ___payement___ */}

      <div className="payment-container"></div>
    </Layout>
  );
};

export default UpdateCommande;
