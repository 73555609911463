import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#E72D32',
//   borderColor: '#0063cc',
  fontFamily: [
    'Montserrat',
  ].join(','),
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#000000',
    boxShadow: '#E72D32',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#E72D32',
    borderColor: '#E72D32',
  },
  '&:focus': {
    // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export default function CustomizedButtons({onClick }) {
  return (
    <Stack spacing={2} direction="row">
      {/* <ColorButton variant="contained">Custom CSS</ColorButton> */}
      <BootstrapButton variant="contained" disableRipple>
        Ajouter une avance
      </BootstrapButton>
    </Stack>
  );
}