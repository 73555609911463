// Layout.js
import React from "react";
import Header from "./Header";
import BottomTab from "./BottomTap";

const Layout = ({ children, leftContent, centerContent, rightContent  }) => {

    const layoutContainer = {
        display: 'flex',
        // flexDirection:'column',
        // justifyContent:' center',    
        // alignItems: 'center',
        // height: '100vh',
        // width: '100%'
        margin:'auto',
        flex: 1
      }
      
  return (
    <div style={{ maxWidth: "500px", margin: "auto" }}>
      <Header 
       leftContent={leftContent}
       centerContent={centerContent}
       rightContent={rightContent}
      />
        <main style={{marginTop:'50px'}}>{children}</main>
      <BottomTab />
    </div>
  );
};

export default Layout;
