/* eslint-disable no-unused-vars */
import {
  ACCUEIL_PATH,
  LOGIN_PATH,
  COMMANDES_PATH,
  CLIENTS_PATH,
  REGISTER_PATH,
  ADD_CLIENT_PATH,
  DETAIL_CLIENT_PATH,
  ADD_COMMANDE_PATH,
  DETAIL_COMMANDE_PATH,
  PROFIL_PATH,
  UPDATE_CLIENTS_PATH,
  UPDATE_COMMANDE_PATH,
  NOTIFICATION_PATH,
  PERSONALISER_PATH,
  INFOSATELIER_PATH,
  MODIF_PARAMETRE_PATH,
  HISTORIQUE_PATH,
  ALL_COMMANDE_BY_CLIENT_PATH
} from "./path/navigation_path";
import React from "react";
import Accueil from "../pages/accueil/Accueil";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import AddCommande from "../pages/commandes/AddCommande";
import Commandes from "../pages/commandes/Commande";
import DetailCommande from "../pages/commandes/DetailCommande";
import Clients from "../pages/clients/Clients";
import AddClient from "../pages/clients/AddClient";
import DetailClient from "../pages/clients/DetailClient";
import ProfilClient from "../component/ProfilTayeur";
import ModificationClient from "../pages/clients/ModificationClient";
import UpdateCommande from "../pages/commandes/UpdateCommande";
import CommandesLivraisonAujourdhui from "../pages/commandes/statutCommande/livraisonDesCommande/CommandesLivraisonAujourdhui";
import Personaliser from "../component/Personaliser";
import InfoAtelier from "../component/InformationAtelier";
import ModificationParametreMesure from "../component/ModifcationParametreMesure";
import Historique from "../pages/commandes/historiquePaiement/Historique";
import CommandeByClient from "../pages/clients/CommmandeDuClient";

export const public_routes = [
  {
    path: LOGIN_PATH,
    element: <Login />,
  },
  {
    path: REGISTER_PATH,
    element: <Register />,
  },
  //   {
  //     path: LOGIN_PATH,
  //     element: <Auth />,
  //   },
];

const navigation_routes = [
  {
    path: ACCUEIL_PATH,
    element: <Accueil />,
  },
  {
    path: CLIENTS_PATH,
    element: <Clients />,
  },
  {
    path: ADD_CLIENT_PATH,
    element: <AddClient />,
  },
  {
    path: DETAIL_CLIENT_PATH,
    element: <DetailClient />,
  },
  {
    path: UPDATE_CLIENTS_PATH,
    element: <ModificationClient />,
  },
  {
    path: COMMANDES_PATH,
    element: <Commandes />,
  },
  {
    path: ADD_COMMANDE_PATH,
    element: <AddCommande />,
  },
  {
    path: UPDATE_COMMANDE_PATH,
    element: <UpdateCommande />,
  },
  {
    path: DETAIL_COMMANDE_PATH,
    element: <DetailCommande />,
  },
  {
    path: PERSONALISER_PATH,
    element: <Personaliser />,
  },
  {
    path: HISTORIQUE_PATH,
    element: <Historique />,
  },
  {
    path: ALL_COMMANDE_BY_CLIENT_PATH,
    element: <CommandeByClient />,
  },
  //   {
  //     path: GALERIE_PATH,
  //     element: <Galleries />,
  //   },
  //   {
  //     path: ADD_GALERIE_PATH,
  //     element: <AddGallerie />,
  //   },
  //   {
  //     path: DETAIL_GALERIE_PATH,
  //     element: <DetailGallerie />,
  //   },
  //   {
  //     path: PARAMETRE_PATH,
  //     element: <Parametre />,
  //   },

  {
    path: PROFIL_PATH,
    element: <ProfilClient />,
  },
  {
    path: INFOSATELIER_PATH,
    element: <InfoAtelier />,
  },
  {
    path: NOTIFICATION_PATH,
    element: <CommandesLivraisonAujourdhui />,
  },
  {
    path: MODIF_PARAMETRE_PATH,
    element: <ModificationParametreMesure />,
  },
];

export default navigation_routes;
