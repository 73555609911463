import { ADD_CLIENT_API_ROUTE, CLIENT_API_ROUTE, COMMANDE_API, DELETE_CLIENT_API_ROUTE, DETAIL_CLIENT_API_ROUTE, UPDATE_CLIENT_API_ROUTE } from "../../routes/api_routes";
import { ApiMananger } from "../apiManager";

const clientApi = ApiMananger.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getClients: build.query({
      query: (id) => {
        return CLIENT_API_ROUTE + id + "/"
      },
      providesTags: ["Clients"],
    }),
    getClientById: build.query({
      query: (id) => {
        console.log({id});
        return DETAIL_CLIENT_API_ROUTE + id + "/"
      },
      providesTags: ["Clients"],
    }),


    addClient: build.mutation({
      query(body) {
        return {
          url: ADD_CLIENT_API_ROUTE,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Clients"],
    }),
    updateClient: build.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: UPDATE_CLIENT_API_ROUTE + id + "/",
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["Clients"],
    }),
    deleteClient: build.mutation({
      query(id) {
        return {
          url: DELETE_CLIENT_API_ROUTE + id + "/",
          method: "DELETE",
        };
      },
      invalidatesTags: ["Clients"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  useUpdateClientMutation,
  useAddClientMutation,
  useDeleteClientMutation,
  useGetClientByIdQuery
} = clientApi;
