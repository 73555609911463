import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  currentParametres: {},
  parametresList: [],
  allParametresList: [],
  statusFilter: "",
};
const ParametresSlice = createSlice({
  name: "parametre",
  initialState,
  reducers: {
    addNewParametres: (state, { payload }) => {
      // Ajoutez la nouvelle parametre au début de la liste
      state.parametresList = [payload, ...state.parametresList];
      state.allParametresList = [payload, ...state.allParametresList];
    },
    archiveParametresInList: (state, { payload }) => {
      let parametres = state.allParametresList.filter(
        (parametre) => parametre.id !== payload.id
      );
      state.allParametresList = parametres;
      state.parametresList = parametres;
    },
    updateParametresInList: (state, { payload }) => {
      let parametre = state.allParametresList.filter(
        (parametre) => parametre.id !== payload.id
      );
      parametre = [payload, ...parametre];
      state.allParametresList = parametre;
      state.parametresList = parametre;
    },
    setCurrentParametres: (state, { payload }) => {
      state.currentParametres = payload;
    },
    // eslint-disable-next-line no-unused-vars
    ressetCurrentParametres: (state, { payload }) => {
      state.currentParametres = {};
    },
    setParametresList: (state, { payload }) => {
      state.parametresList = payload;
      state.allParametresList = payload;
    },
  },
});

export const {
  addNewParametres,
  setCurrentParametres,
  ressetCurrentParametres,
  setParametresList,
  updateParametresInList,
} = ParametresSlice.actions;
export default ParametresSlice.reducer;
