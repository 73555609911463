import React from 'react'

const MesureComponent = ({text, Texvalue, input }) => {
  return (
    <div className='d-flex justify-content-between' style={{maxWidth:'1000px'}}>
    <p className=" p-2 hommeTitle text-uppercase">{text}</p>
    <p className=" w-25 text-center rounded p-2 fm hommeMesure" style={{ backgroundColor: "#fff", color: "#000000", border: "1px solid black"}}>{Texvalue}</p>
  </div>
  )
}

export default MesureComponent