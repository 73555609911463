import React, { useEffect, useState } from "react";
import { FaCalendarCheck } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useAddCommandeMutation } from "../../redux/features/commandeFeature";
import Header from "../../component/templateMobile/Header";
import "./css/addCommande.css";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { COMMANDES_PATH } from "../../routes/path/navigation_path";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useGetClientsQuery } from "../../redux/features/clientFeature";
import { FiPlus, FiUpload } from "react-icons/fi";
import Select from "react-select";
import { useAddTransactionMutation } from "../../redux/features/transaction";
import { addNewCommande } from "../../redux/slices/commandeSlice";
import Layout from "../../component/templateMobile/TemplateMobile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import AddCommandeInClient from "../clients/AddCommandeInClient";
import AddClientInCommande from "./AddClientInCommande";
import CircularWithValueLabel from "../../utils/Material-ui/composantChargement";

const AddCommande = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [addedClientId, setAddedClientId] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  const { data, error } = useGetClientsQuery(currentUser?.id);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const [addCommande] = useAddCommandeMutation();
  const [addTransaction] = useAddTransactionMutation();
  const [selectedClient, setSelectedClient] = useState(null);
  const [montantRestant, setMontantRestant] = useState(0);
  const [montantPayeFromApiCompta, setMontantPayeFromApiCompta] = useState(0);
  const [montantState, setMontantState] = useState();

  const [avanceError, setAvanceError] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [errorFields, setErrorFields] = useState([]);
  // Image preview
  // eslint-disable-next-line no-unused-vars
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [isInvalidPrice, setIsInvalidPrice] = useState(false);
  const [isInvalidAvance, setIsInvalidAvance] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const [dateError, setDateError] = useState(null);

  const listClient = useSelector((state) => state.clients?.clientsList);
  const [validateTissuName, setValidateTissuName] = useState(false);

  // const { clientId } = useParams();
  const options = data?.map((item) => ({
    value: item.id,
    label: item.nom_complet,
  }));

  // console.log({selectedClient})

  const handleTissu = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)
    ) {
      e.preventDefault();
      setValidateTissuName(true);
    } else {
      setValidateTissuName(false);
    }
  };
  const [imageLoading, setImageLoading] = useState(false);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);

    // Rest of your existing code for previewing the first image
    //  const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
      setImageLoading(false);
    };
    reader.readAsDataURL(file);
  };
  console.log({ previewImage });
  // Fin
  useEffect(() => {
    const montant = parseInt(montantState);
    const montantPaye = montantPayeFromApiCompta;
    const montantRestant = montant - montantPaye;
    setMontantRestant(montantRestant);
  }, [montantState, montantPayeFromApiCompta]);

  const validateAge = (value) => {
    if (!value) return true;
    if (isNaN(value) || value < 0 || value > 99 || !/^\d{1,2}$/.test(value)) {
      return false;
    }
    return true;
  };

  const validatePrice = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };

  const validateDelivredDateCommande = (value) => {
    const today = new Date(Date.now());
    const formatdate = `${today.getDate()}-${
      today.getMonth() + 1
    }-${today.getFullYear()}`;
    const delivredDate = Date.parse(value);
    // console.log({ formatdate: Date.parse(formatdate), delivredDate });
    if (Date.parse(formatdate) <= delivredDate) {
      return false;
    }

    return true;
  };

  const validateAvance = (value) => {
    if (!value) return true;
    if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
      return false;
    }
    return true;
  };

  const handlePrice = (e) => {
    const inputValue = e.target.value + e.key;
    if (e.key !== "Backspace" && (isNaN(e.key) || !validatePrice(inputValue))) {
      e.preventDefault();
      setIsInvalidPrice(true);
    } else {
      setIsInvalidPrice(false);
    }
  };
  const handleAvance = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      (isNaN(e.key) || !validateAvance(inputValue))
    ) {
      e.preventDefault();
      setIsInvalidAvance(true);
    } else {
      setIsInvalidAvance(false);
    }
  };

  const handleNumericInput = (e) => {
    const inputValue = e.target.value + e.key;
    if (
      e.key !== "Backspace" &&
      e.key !== "Tab" &&
      (isNaN(e.key) || !validateAge(inputValue))
    ) {
      e.preventDefault();
      setIsInvalidInput(true);
    } else {
      setIsInvalidInput(false);
    }
  };
  const handleSubmitCommande = (data) => {
    setIsLoading(true);

    if (data.modele && data.modele[0]) {
      // Cas où "modele" est défini
      data.clientId = selectedClient?.value;
      const formattedDate = format(selectedDate, "yyyy-MM-dd'T'HH:mm");
      const newData = new FormData();
      newData.append("clientId", parseInt(selectedClient?.value));
      newData.append("nom_tissu", data.nom_tissu);
      newData.append("metre_tissu", parseInt(data.metre_tissu));
      newData.append("montant", parseInt(montantState));
      newData.append("montant_paye", montantPayeFromApiCompta);
      newData.append("date_livraison", formattedDate);
      newData.append("modele", data.modele[0]);
      newData.append("createdBy", currentUser.id);
      // Append each selected image to FormData
      for (let i = 0; i < selectedImage.length; i++) {
        newData.append(`modele[${i}]`, selectedImage[i]);
      }
      console.log(newData);

      // Appeler la mutation addCommande avec FormData
      addCommande({ body: newData })
        .unwrap()
        .then((response) => {
          const newTransaction = {
            createdBy: response?.createdBy,
            commande: response.id,
            montant_paye: parseInt(montantPayeFromApiCompta),
          };
          addTransaction(newTransaction)
            .then((res) => {
              setMontantPayeFromApiCompta(res.montant_paye);
            })
            .catch(() => {
              toast.error("Erreur lors de l'ajout de la commande");
            });
          console.log("Commande ajoutée avec succès !");
          navigate(COMMANDES_PATH);
        })
        .catch((error) => {
          console.error("Erreur lors de l'ajout de la commande :", error);
        });
    } else {
      // Cas où "modele" n'est pas défini
      data.clientId = selectedClient?.value;
      data.createdBy = currentUser.id;
      let { clientId } = data;
      const newCommande = {
        clientId: parseInt(clientId),
        nom_tissu: data.nom_tissu,
        metre_tissu: parseInt(data?.metre_tissu),
        montant: parseInt(montantState),
        montant_paye: parseInt(montantPayeFromApiCompta),
        restant: montantRestant,
        date_livraison: data?.date_livraison,
        createdBy: currentUser.id,
      };
      addCommande({ body: newCommande })
        .unwrap()
        .then((response) => {
          const newTransaction = {
            createdBy: response?.createdBy,
            commande: response.id,
            montant_paye: parseInt(montantPayeFromApiCompta),
          };
          addTransaction(newTransaction)
            .then((res) => {
              setMontantPayeFromApiCompta(res.montant_paye);
            })
            .catch(() => {
              toast.error("Erreur lors de l'ajout de la commande");
            });
          console.log("Commande ajoutée avec succès !");
          toast.success("Ajout commande succès !"); // Utilisez toast pour l'alerte de succès
          setTimeout(() => {
            navigate(COMMANDES_PATH);
          }, 1000);
        })
        .catch((error) => {
          console.error("Erreur lors de l'ajout de la commande :", error);
        });
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    dispatch(addNewCommande(data));
  };

  // Dans la fonction handleMontantPayeChange
  const handleMontantPayeChange = (value) => {
    const inputValue = parseFloat(value);
    if (!isNaN(inputValue)) {
      // Mettez à jour le montant payé directement ici
      setMontantPayeFromApiCompta(inputValue);
      const newMontantRestant = montantState - inputValue;
      if (inputValue <= montantState) {
        setMontantRestant(newMontantRestant >= 0 ? newMontantRestant : 0);
        setErrorState(false);
        setAvanceError(null);
      } else {
        setErrorState(true);
        setAvanceError(
          "Le montant de l'avance ne peut pas dépasser le montant du prix confection."
        );
      }
    } else if (value === "" || value === null) {
      // Effacez le champ s'il est vide ou nul
      setMontantPayeFromApiCompta(null);
      setMontantRestant(montantState); // Mettez à jour le montant restant avec montantState
      setErrorState(false);
      setAvanceError(null);
    }
  };

  const handleMontantPayeKeyDown = (e) => {
    const inputValue = parseFloat(e.target.value + e.key);
    // Permettez uniquement les caractères numériques et la touche "Backspace"
    if (e.key !== "Backspace" && (isNaN(e.key) || inputValue < 0)) {
      e.preventDefault();
    }
  };

  const handleGoBack = () => {
    navigate(COMMANDES_PATH);
  };

  const leftContent = <IoIosArrowBack size={24} onClick={handleGoBack} />;

  const centerContent = (
    <div className="titre-header">
      <span>Ajouter Commande</span>
    </div>
  );

  const rightContent = null;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <div className="container ajout-client">
        <ToastContainer />
        <div className="card-input">
          <form onSubmit={handleSubmit(handleSubmitCommande)}>
            <div className="add-commande-container">
              <div className="add-commande-client-section">
                {/* Client */}
                <div className="row section-content displaySelectAndAddClient">
                  <div className="row m-1">
                    {/* <h3 className="section-title">Client</h3> */}
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                      <label htmlFor="clientId" className="labelSmalDevice">
                        Nom client
                      </label>
                    </div>
                    <div
                      style={{ textAlign: "center", marginTop: "5px" }}
                    ></div>
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                      <Select
                        className="onSmallDevicce"
                        name="clientId"
                        id="clientId"
                        options={options}
                        placeholder="Choisissez un client"
                        isClearable={true}
                        {...register("clientId")}
                        value={selectedClient || null}
                        onChange={(client) =>
                          client?.value !== "+" && setSelectedClient(client)
                        }
                        required
                      />

                      {errors.clientId && (
                        <p className="error-msg text-center">
                          &nbsp; {errors.clientId.message}
                        </p>
                      )}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                      <button
                        type="button"
                        className="btn btn-danger mb-2"
                        style={{ width: "40px" }}
                        onClick={openModal}
                      >
                        <FiPlus />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mb-4">
                  <AddClientInCommande
                    closeModal={closeModal}
                    showModal={showModal}
                    data={data}
                    setAddedClientId={setAddedClientId}
                  />
                </div>
                {/* Commandes */}
                <div className="row section-content mb-4">
                  <div className="row m-1">
                    {/* <h3 className="section-title">Commande</h3> */}
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <label htmlFor="nom_tissus">Nom du tissu</label>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <input
                        type="text"
                        name="nom_tissu"
                        id="nom_tissu"
                        className="form-control"
                        {...register("nom_tissu", {
                          required: "Champ obligatoire !",
                        })}
                        onKeyDown={handleTissu}
                      />
                      {validateTissuName && (
                        <p className="error-msg">
                          Veuillez entrer un nom de tissu valide !
                        </p>
                      )}
                      {errors.nom_tissu && (
                        <p className="error-msg text-center">
                          {" "}
                          &nbsp; {errors.nom_tissu.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-1">
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <label htmlFor="quantite">Longueur tissu (m / y)</label>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <input
                        type="number"
                        name="metre_tissu"
                        id="metre_tissu"
                        className="form-control"
                        {...register("metre_tissu", {
                          required: "Champ obligatoire !",
                        })}
                        onKeyDown={handleNumericInput}
                      />
                      {isInvalidInput && (
                        <p className="error-msg">
                          Veuillez entrer un nombre valide.
                        </p>
                      )}
                      {errors.metre_tissu && (
                        <p className="error-msg text-center">
                          {" "}
                          &nbsp; {errors.metre_tissu.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-1">
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <label htmlFor="prix">Prix confection</label>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <input
                        type="number"
                        name="montant"
                        id="montant"
                        className="form-control"
                        {...register("montantState", {
                          required: "Champ obligatoire !",
                        })}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          // Check if the input length is greater than 6
                          if (inputValue.length <= 6) {
                            setMontantState(inputValue);
                          }
                        }}
                        value={montantState || ""}
                        onKeyDown={handlePrice}
                      />
                      {isInvalidPrice && (
                        <p className="error-msg">
                          Veuillez entrer un nombre valide.
                        </p>
                      )}
                      {errors.montantState && (
                        <p className="error-msg text-center">
                          &nbsp; {errors.montantState.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row m-1">
                    <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <label htmlFor="modele">Modèle / tissu</label>
                    </div>
                    <div className="modeleImage col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <button className="modeleLabel">
                        <FiUpload /> Importer une image
                      </button>
                      {/* Container for the image and CircularProgress */}
                      <div style={{ position: "relative" }}>
                        {imageLoading && <CircularWithValueLabel />}
                        {previewImage && (
                          <img
                            src={previewImage}
                            alt="modele"
                            style={{
                              marginTop: "5px",
                              width: "200px",
                              height: "200px",
                            }}
                          />
                        )}
                      </div>
                      <input
                        type="file"
                        name="modele"
                        {...register("modele")}
                        id="modele"
                        className="form-control"
                        onChange={handleFileUpload}
                        multiple
                      />
                      {/* </div> */}
                    </div>
                  </div>

                  {/* <AddClientForm showModal={showModal} setShowModal={setShowModal} /> */}

                  <div className="row m-1">
                    <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <label htmlFor="date_livraison">Date de livraison</label>
                    </div>
                    <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <DatePicker
                        name="date_livraison"
                        id="date_livraison"
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                      {errors.date_livraison && (
                        <p className="error-msg">
                          &nbsp; {errors.date_livraison.message}
                        </p>
                      )}
                      {dateError && (
                        <p className="error-msg">&nbsp; {dateError}</p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Paiement */}
                {/* <h3 className="section-title">Modalité de paiement</h3> */}
                {/* <div className="row section-content"> */}
                <div className="row m-1">
                  <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                    <label htmlFor="montant_avance">Montant avancé</label>
                  </div>
                  <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                    <input
                      type="number"
                      name="montant_avance"
                      id="montant_avance"
                      className="form-control"
                      {...register("montantPayeFromApiCompta")}
                      onChange={(e) => {
                        const inputValue = parseInt(e.target.value);
                        const newMontantPaye = inputValue >= 0 ? inputValue : 0;
                        const newMontantRestant = montantState - newMontantPaye;

                        // Vérifier si montantPaye dépasse le montant total
                        if (newMontantPaye <= montantState) {
                          setMontantPayeFromApiCompta(newMontantPaye);
                          setMontantRestant(
                            newMontantRestant >= 0 ? newMontantRestant : 0
                          );
                          setErrorState(false);
                        } else {
                          // Afficher une erreur lorsque montantPaye dépasse le montant total
                          setMontantPayeFromApiCompta(montantPayeFromApiCompta);
                          setMontantRestant(montantRestant);
                          setErrorState(true);
                          // Afficher un message d'erreur ou utiliser une variable d'état pour indiquer une erreur
                          // Exemple : setErrorState(true);
                        }
                      }}
                      value={
                        montantPayeFromApiCompta !== 0
                          ? montantPayeFromApiCompta
                          : ""
                      }
                      onKeyDown={handleAvance}
                    />
                    {isInvalidAvance && avanceError && (
                      <p className="error-msg">{avanceError}</p>
                    )}
                    {errors.metre_tissu && (
                      <p className="error-msg ">
                        {" "}
                        &nbsp; {errors.metre_tissu.message}
                      </p>
                    )}
                    {errorState && (
                      <p className="error-msg">
                        {" "}
                        &nbsp; Le montant de l'avance ne peut pas dépasser le
                        montant du prix confection.
                      </p>
                    )}
                  </div>
                  {/* </div> */}

                  <div className="mt-2">
                    <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
                      <label htmlFor="montant_restant">Montant restant</label>
                    </div>
                    <div className=" ">
                      <input
                        type="number"
                        name="montant_restant"
                        id="montant_restant"
                        className="form-control"
                        disabled
                        value={montantPayeFromApiCompta ? montantRestant : ""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="validateButton mt-2"
                type="submit"
                style={{ width: "91%", marginLeft: "16px" }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-white"
                    role="status"
                  ></div>
                ) : (
                  "Valider"
                )}
              </button>
            </div>
            <br></br>
            <br></br>
            <br></br>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default AddCommande;
