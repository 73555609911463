import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useGetClientByIdQuery } from '../../redux/features/clientFeature';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Modal, Button, Form } from 'react-bootstrap';
import { addNewCommande } from '../../redux/slices/commandeSlice';
import { FiUpload } from 'react-icons/fi';
import DatePicker from "react-datepicker";
import { useAddCommandeMutation } from '../../redux/features/commandeFeature';
import { useAddTransactionMutation } from '../../redux/features/transaction';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { COMMANDES_PATH } from '../../routes/path/navigation_path';


const  AddCommandeInClient = ({closeModal,showModal, data}) => {

    const id = data?.id
    const [dateError, setDateError] = useState(null);
    const navigate = useNavigate();

  
    const [addCommande] = useAddCommandeMutation();
    const [addTransaction] = useAddTransactionMutation()
    const currentUser = useSelector((state) => state?.UserSlice?.info);
    const dispatch = useDispatch();
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      reset,
    } = useForm();
    const [previewImage, setPreviewImage] = useState(null);
    const [isInvalidInput, setIsInvalidInput] = useState(false);
    const [isInvalidPrice, setIsInvalidPrice] = useState(false);
    const [isInvalidAvance, setIsInvalidAvance] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [montantRestant, setMontantRestant] = useState(0);
    const [montantPayeFromApiCompta, setMontantPayeFromApiCompta] = useState(0);
    const [montantState, setMontantState] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [validateTissuName, setValidateTissuName] = useState(false);

    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleTissu = (e) => {
        const inputValue = e.target.value + e.key;
        if (
          e.key !== "Backspace" &&
          e.key !== "Tab" &&
          !/^[a-zA-ZÀ-ÿ\s]*$/.test(inputValue)
        ) {
          e.preventDefault();
          setValidateTissuName(true);
        } else {
          setValidateTissuName(false);
        }
      };
    
      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setSelectedImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
      };
      // Fin
      useEffect(() => {
        const montant = parseInt(montantState);
        const montantPaye = montantPayeFromApiCompta;
        const montantRestant = montant - montantPaye;
        setMontantRestant(montantRestant);
      }, [montantState, montantPayeFromApiCompta]);
    
      const validateAge = (value) => {
        if (!value) return true;
        if (isNaN(value) || value < 0 || value > 99 || !/^\d{1,2}$/.test(value)) {
          return false;
        }
        return true;
      };
    
      const validatePrice = (value) => {
        if (!value) return true;
        if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
          return false;
        }
        return true;
      };
    
      const validateDelivredDateCommande = (value) => {
        const today = new Date(Date.now());
        const formatdate = `${today.getDate()}-${
          today.getMonth() + 1
        }-${today.getFullYear()}`;
        const delivredDate = Date.parse(value);
        // console.log({ formatdate: Date.parse(formatdate), delivredDate });
        if (Date.parse(formatdate) <= delivredDate) {
          return false;
        }
    
        return true;
      };
      const validateAvance = (value) => {
        if (!value) return true;
        if (isNaN(value) || parseFloat(value) < 1 || !/^[1-9]\d*$/.test(value)) {
          return false;
        }
        return true;
      };
    
      const handlePrice = (e) => {
        const inputValue = e.target.value + e.key;
        if (e.key !== "Backspace" && (isNaN(e.key) || !validatePrice(inputValue))) {
          e.preventDefault();
          setIsInvalidPrice(true);
        } else {
          setIsInvalidPrice(false);
        }
      };
      const handleAvance = (e) => {
        const inputValue = e.target.value + e.key;
        if (
          e.key !== "Backspace" &&
          e.key !== "Tab" &&
          (isNaN(e.key) || !validateAvance(inputValue))
        ) {
          e.preventDefault();
          setIsInvalidAvance(true);
        } else {
          setIsInvalidAvance(false);
        }
      };
    
      const handleNumericInput = (e) => {
        const inputValue = e.target.value + e.key;
        if (
          e.key !== "Backspace" &&
          e.key !== "Tab" &&
          (isNaN(e.key) || !validateAge(inputValue))
        ) {
          e.preventDefault();
          setIsInvalidInput(true);
        } else {
          setIsInvalidInput(false);
        }
      };
    
    
    
      // Dans la fonction handleMontantPayeChange
      const handleMontantPayeChange = (value) => {
        const inputValue = parseFloat(value);
        if (!isNaN(inputValue)) {
          // Mettez à jour le montant payé directement ici
          setMontantPayeFromApiCompta(inputValue);
          const newMontantRestant = montantState - inputValue;
          if (inputValue <= montantState) {
            setMontantRestant(newMontantRestant >= 0 ? newMontantRestant : 0);
            setErrorState(false);
          } else {
            setErrorState(true);
          }
        } else if (value === "" || value === null) {
          // Effacez le champ s'il est vide ou nul
          setMontantPayeFromApiCompta(null);
          setMontantRestant(montantState); // Mettez à jour le montant restant avec montantState
          setErrorState(false);
        }
      };
      const [isLoading, setIsLoading] = useState(false);

    
      const handleMontantPayeKeyDown = (e) => {
        const inputValue = parseFloat(e.target.value + e.key);
        // Permettez uniquement les caractères numériques et la touche "Backspace"
        if (e.key !== "Backspace" && (isNaN(e.key) || inputValue < 0)) {
          e.preventDefault();
        }
      };
    const handleSubmitCommande = (data) => {
        setIsLoading(true);
    
        if (data.modele && data.modele[0]) {
          // Cas où "modele" est défini
        //   data.clientId = selectedClient?.value;
          const formattedDate = format(selectedDate, 'yyyy-MM-dd\'T\'HH:mm');
          const newData = new FormData();
          newData.append("clientId", parseInt(id));
          newData.append("nom_tissu", data.nom_tissu);
          newData.append("metre_tissu", parseInt(data.metre_tissu));
          newData.append("montant", parseInt(montantState));
          newData.append("montant_paye", montantPayeFromApiCompta);
          newData.append("date_livraison", formattedDate);
          newData.append("modele", data.modele[0]);
          newData.append("createdBy", currentUser.id);
    
          console.log(newData);
    
          addCommande({ body: newData })
            .unwrap()
            .then((response) => {
              console.log(response)
              const newTransaction = {
                createdBy: response?.createdBy,
                commande: response.id,
                montant_paye: parseInt(montantPayeFromApiCompta),
              };
              addTransaction(newTransaction)
                .then((res) => {
                  setMontantPayeFromApiCompta(res.montant_paye);
                })
                .catch(() => {
                  toast.error("Erreur lors de l'ajout de la commande");
                });
              console.log("Commande ajoutée avec succès !");
              navigate(COMMANDES_PATH);
            })
            .catch((error) => {
              console.error("Erreur lors de l'ajout de la commande :", error);
            });
        } else {
          data.clientId = selectedClient?.value;
          data.createdBy = currentUser.id;
         console.log(id)
          const newCommande = {
            clientId: parseInt(id),
            nom_tissu: data.nom_tissu,
            metre_tissu: parseInt(data?.metre_tissu),
            montant: parseInt(montantState),
            montant_paye: parseInt(montantPayeFromApiCompta),
            restant: montantRestant,
            date_livraison: data?.date_livraison,
            createdBy: currentUser.id,
          };
        //   console.log({NIMAG:newCommande})
          addCommande({ body: newCommande })
            .unwrap()
            .then((response) => {
              const newTransaction = {
                createdBy: response?.createdBy,
                commande: response.id,
                montant_paye: parseInt(montantPayeFromApiCompta),
              };
              addTransaction(newTransaction)
                .then((res) => {
                  setMontantPayeFromApiCompta(res.montant_paye);
                })
                .catch(() => {
                  toast.error("Erreur lors de l'ajout de la commande");
                });
              console.log("Commande ajoutée avec succès !");
              toast.success("Ajout commande succès !"); 
              setTimeout(() => {
                navigate(COMMANDES_PATH);
              }, 1000);
            })
            .catch((error) => {
              console.error("Erreur lors de l'ajout de la commande :", error);
            });
        }
    
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        dispatch(addNewCommande(data));
      };
  return (
    <Modal show={showModal} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title style={{ color: "red", textAlign: "center"}}>Ajout Commande</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form onSubmit={handleSubmit(handleSubmitCommande)}>
    <div className="add-commande-container">
      <div className="add-commande-client-section">
        {/* Client */}
        <div className="row section-content displaySelectAndAddClient">
          <div className="row m-1">
            {/* <h3 className="section-title">Client</h3> */}
            <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="clientId" className="labelSmalDevice">
                Nom client
              </label>
            </div>

            <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <input
                className="form-control"
                name="clientId"
                id="clientId"
                value={data?.nom_complet}
                placeholder="Choisissez un client"
                isClearable={true}
                {...register("clientId")}
                disabled
              />

              {errors.clientId && (
                <p className="error-msg text-center">
                  &nbsp; {errors.clientId.message}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Commandes */}
        <div className="row section-content mb-4">
          <div className="row m-1">
            {/* <h3 className="section-title">Commande</h3> */}
            <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="nom_tissus">Nom du tissu</label>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <input
                type="text"
                name="nom_tissu"
                id="nom_tissu"
                className="form-control"
                {...register("nom_tissu", {
                  required: "Champ obligatoire !",
                })}
                onKeyDown={handleTissu}
              />
              {validateTissuName && (
                <p className="error-msg">
                  Veuillez entrer un nom de tissu valide !
                </p>
              )}
              {errors.nom_tissu && (
                <p className="error-msg text-center">
                  {" "}
                  &nbsp; {errors.nom_tissu.message}
                </p>
              )}
            </div>
          </div>

          <div className="row m-1">
          <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="quantite">Longueur tissu (m / y)</label>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <input
                type="number"
                name="metre_tissu"
                id="metre_tissu"
                className="form-control"
                {...register("metre_tissu", {
                  required: "Champ obligatoire !",
                })}
                onKeyDown={handleNumericInput}
              />
              {isInvalidInput && (
                <p className="error-msg">
                  Veuillez entrer un nombre valide.
                </p>
              )}
              {errors.metre_tissu && (
                <p className="error-msg text-center">
                  {" "}
                  &nbsp; {errors.metre_tissu.message}
                </p>
              )}
            </div>
          </div>

          <div className="row m-1">
          <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="prix">Prix confection</label>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <input
                type="number"
                name="montant"
                id="montant"
                className="form-control"
                {...register("montantState", {
                  required: "Champ obligatoire !",
                })}
                onChange={(e) => setMontantState(e.target.value)}
                value={montantState || ""}
                onKeyDown={handlePrice}
              />
              {isInvalidPrice && (
                <p className="error-msg">
                  Veuillez entrer un nombre valide.
                </p>
              )}
              {errors.montantState && (
                <p className="error-msg text-center">
                  {" "}
                  &nbsp; {errors.montantState.message}
                </p>
              )}
            </div>
          </div>

          <div className="row m-1">
          <div className="col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="modele">Modèle / tissu</label>
            </div>
            <div className="modeleImage col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <button className="modeleLabel">
                <FiUpload /> Importer une image
              </button>
              {previewImage && (
                <img
                  src={previewImage}
                  alt="modele"
                  style={{
                    marginTop: "5px",
                    width: "200px",
                    height: "200px",
                  }}
                />
              )}
              <input
                type="file"
                name="modele"
                {...register("modele")}
                id="modele"
                className="form-control"
                onChange={handleFileUpload}
              />
            </div>
          </div>

          <div className="row m-1">
          <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="date_livraison">Date de livraison</label>
            </div>
            <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
            <DatePicker
              name="date_livraison"
              id="date_livraison"
              selected={selectedDate} 
              onChange={(date) => setSelectedDate(date)}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
            {errors.date_livraison && (
              <p className="error-msg">&nbsp; {errors.date_livraison.message}</p>
            )}
            {dateError && (
              <p className="error-msg">&nbsp; {dateError}</p>
            )}
            </div>
          </div>
        </div>

          <div className="row m-1">
          <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="montant_avance">Montant avancé</label>
            </div>
            <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <input
                type="number"
                name="montant_avance"
                id="montant_avance"
                className="form-control"
                {...register("montantPayeFromApiCompta")}
                onChange={(e) => {
                  const inputValue = parseInt(e.target.value);
                  const newMontantPaye =
                    inputValue >= 0 ? inputValue : 0;
                  const newMontantRestant =
                    montantState - newMontantPaye;

                  // Vérifier si montantPaye dépasse le montant total
                  if (newMontantPaye <= montantState) {
                    setMontantPayeFromApiCompta(newMontantPaye);
                    setMontantRestant(
                      newMontantRestant >= 0 ? newMontantRestant : 0
                    );
                    setErrorState(false);
                  } else {
                    // Afficher une erreur lorsque montantPaye dépasse le montant total
                    setMontantPayeFromApiCompta(
                      montantPayeFromApiCompta
                    );
                    setMontantRestant(montantRestant);
                    setErrorState(true);
                    // Afficher un message d'erreur ou utiliser une variable d'état pour indiquer une erreur
                    // Exemple : setErrorState(true);
                  }
                }}
                value={
                  montantPayeFromApiCompta !== 0
                    ? montantPayeFromApiCompta
                    : ""
                }
                onKeyDown={handleAvance}
              />
              {isInvalidAvance && (
                <p style={{ color: "red" }}>
                  Veuillez entrer un nombre valide.
                </p>
              )}
              {errors.metre_tissu && (
                <p className="error-msg ">
                  {" "}
                  &nbsp; {errors.metre_tissu.message}
                </p>
              )}
              {errorState && (
                <p className="error-msg">
                  {" "}
                  &nbsp; Le montant de l'avance ne peut pas dépasser le
                  montant du prix confection.
                </p>
              )}
            </div>
          {/* </div> */}

          <div className="mt-2">
          <div className=" col-xl-12 col-lg-12 col-md-10 col-sm-12 col-12">
              <label htmlFor="montant_restant">Montant restant</label>
            </div>
            <div className=" ">
              <input
                type="number"
                name="montant_restant"
                id="montant_restant"
                className="form-control"
                disabled
                value={montantPayeFromApiCompta ? montantRestant : ""}
              />
            </div>
          </div>
        </div>
      </div>
       <button className="validateButton mt-2" type="submit" style={{width:"91%", marginLeft:'16px'}}>
          {isLoading ? (
            <div
              className="spinner-border text-white"
              role="status"
            ></div>
          ) : (
            "Valider"
          )}
        </button>
    </div>
    <br></br>
    <br></br>
    <br></br>
  </form>
    </Modal.Body>
  </Modal>
  )
}

export default AddCommandeInClient