import React, { useEffect, useState } from "react";
import Header from "../../component/templateMobile/Header";
import "./css/detailCommande.css";
import { IoIosArrowBack } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuPenLine } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import { BsCheck2Circle, BsImageFill } from "react-icons/bs";
import {
  useGetCommandeQuery,
  useGetCommandesByUserQuery,
} from "../../redux/features/commandeFeature";
import {
  COMMANDES_PATH,
  UPDATE_COMMANDE_PATH,
} from "../../routes/path/navigation_path";
import moment from "moment/moment";
import DeleteCommande from "./DeleteCommande";
import { DEFAULT_API_IMAGE } from "../../routes/api_routes";
import "../../utils/GlobalStyle.css";
import EditStatutCommande from "./statutCommande/EditStatutCommande";
import Layout from "../../component/templateMobile/TemplateMobile";
import SkeletonComponent from "../../utils/Material-ui/skeleton";
import CustomizedButtons from "../../utils/Material-ui/buttonCom";
import HistoryIcon from "@mui/icons-material/History";
import ModalPaiement from "./historiquePaiement/ModalPaiement";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Modal from "../../component/ModalFacture";
import logo from "../../../src/assets/img/tayeur-logo1.png";
import imprimer from "../../assets/img/file-text.png";
import { toast } from "react-toastify";

const DetailCommande = () => {
  const currentUser = useSelector((state) => state?.UserSlice?.info);
  //suppression commande
  const { id } = useParams();
  // console.log({id});
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commandeIdToDelete, setCommandeIdToDelete] = useState(null);

  const handleDeleteCommande = (id) => {
    setCommandeIdToDelete(id);
    setShowDeleteModal(true);
  };

  const { data: commande, isLoading } = useGetCommandeQuery(id);

  const [commandeStatut, setCommandeStatut] = useState(commande?.statut);
  const [commandeId, setCommandeId] = useState(null); // Assurez-vous d'initialiser la commandeId
  const [paie, setPaie] = useState(null); // replace 'null' with your actual initial value

  const handleStatutChange = (nouveauStatut) => {
    setCommandeStatut(nouveauStatut);
  };

  const handleUpdateCommande = () => {
    if (commande?.statut === 'livree') {
      // If the command is delivered, show a toast message
      toast.warning("Modification not allowed for delivered commands.");
    } else {
      // Navigate to the update-commande page
      navigate(`/update-commande/${id}`);
    }
  };
  const leftContent = (
    <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
      <IoIosArrowBack size={24} />
    </div>
  );
  console.log({ commande });
  const centerContent = (
    <div className="titre-header">
      <span>Detail Commande</span>
    </div>
  );
  const rightContent = (
    <div className="d-flex" style={{ color: "#E82828" }}>
      <RiDeleteBin6Line
        style={{ marginRight: "10px" }}
        onClick={() => handleDeleteCommande(id)}
      />
      <LuPenLine onClick={handleUpdateCommande} />
    </div>
  );

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
    }, 200); // Définissez le temps d'attente en millisecondes (ici, 2000 ms ou 2 secondes)

    return () => {
      clearTimeout(loaderTimeout); // Assurez-vous de nettoyer le timeout lors du démontage du composant
    };
  }, []);

  // modal Paiement
  const [showPaymentMessage, setShowPaymentMessage] = useState(false);
  console.log({ showPaymentMessage });
  const handleShowModal = () => {
    if (commande?.montant_restant === 0) {
      setShowPaymentMessage(true);
    }
  };

  useEffect(() => {
    setShowPaymentMessage(commande?.montant_restant === 0);
  }, [commande?.montant_restant, commande?.montant_paye]);

  const isCommandePayee = () => {
    return commande?.montant_restant === 0;
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  // mise a jour du montantPaye


  const handleModalHide = () => {
    setIsModalOpen(false);
    setShowLoader(true); // Mettez à jour showLoader lorsque le modal est fermé
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout
      leftContent={leftContent}
      centerContent={centerContent}
      rightContent={rightContent}
    >
      <br></br>
      <br></br>
      {showLoader ? ( // Affiche le Skeleton Loader lorsque isLoading est vrai
        <SkeletonComponent /> // Assurez-vous d'ajuster cela en fonction de vos besoins spécifiques
      ) : (
        <div>
          <div className="">
            <div className="comcom">
              <span className="small-title ">client: </span>
              <span style={{ fontWeight: 600, textTransform: "uppercase" }}>
                {commande?.client?.nom_complet}
              </span>
            </div>
            <Link
              to={`/detail-client/${commande?.clientId}`}
              style={{ textDecoration: "none" }}
            >
              <div className="comcom">
                <RemoveRedEyeOutlinedIcon
                  style={{ color: "#E82828" }}
                  className="mx-2"
                />
              </div>
            </Link>
          </div>

          <div className="facture-container ">
            {isCommandePayee() && (
              <button
                className="btn btn-danger bouton-facturer"
                onClick={openModal}
              >
                <img src={imprimer} alt="Imprimer" />
                &nbsp; Facturer
              </button>
            )}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              {/* Contenu de votre modal */}
              <div className=" imprimente">
                <div>
                  <img src={logo} />
                </div>
                <div className="date">
                  <p className="tayeur_p">
                    {" "}
                    Date de livraison:{" "}
                    {moment(commande?.date_livraison).format("DD/MM/YYYY ")}
                  </p>
                </div>
              </div>
              {/* Vous pouvez ajouter ici le contenu de votre facture */}
            </Modal>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px",
            }}
          >
            <span className="small-title ">Changement status commande</span>
            <EditStatutCommande
              commandeStatut={commandeStatut}
              onStatutChange={handleStatutChange}
              id={id}
              commande={commande}
            />
          </div>
          {showDeleteModal && (
            <DeleteCommande
              showModal={showDeleteModal}
              setShowModal={setShowDeleteModal}
              commandeIdToDelete={commandeIdToDelete}
              onDeleted={() => {
                // Cette fonction sera appelée après la suppression de la commande
                // Vous pouvez mettre à jour l'état ou effectuer d'autres actions ici
              }}
            />
          )}
          {commande && (
            <div>
              <div className="section1">
                <div className="com">
                  <span className="small-title ">Status commande</span>
                  <span>{commande?.statut}</span>
                </div>
                <div className="com">
                  <span className="small-title ">Paiement</span>
                  {commande?.montant_restant === 0 ? (
                    <span style={{ color: "#12C28D" }}>Payée</span>
                  ) : commande?.montant === commande?.montant_restant ? (
                    <span style={{ color: "#E82828" }}>Impayée</span>
                  ) : (
                    <span style={{ color: "#F0A113" }}>
                      En cours de paiement
                    </span>
                  )}
                </div>
                <div className="com">
                  <span className="small-title ">Date commande</span>
                  <span>
                    {moment(commande?.date_commande).format("DD/MM/YYYY HH:mm")}
                  </span>
                </div>
                <div className="com">
                  <span className="small-title ">Date de livraison</span>
                  <span>
                    {moment(commande?.date_livraison).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </span>
                </div>

                <div className="com">
                  <span className="small-title ">Nom du tissu</span>
                  <h6>{commande?.nom_tissu}</h6>
                </div>
                <div className="com">
                  <span className="small-title ">Longueur tissu</span>
                  <h6>{commande?.metre_tissu}</h6>
                </div>
                <div className="com">
                  <span className="small-title ">Images tissu</span>
                </div>
                <div className="mx-2">
                  {commande.modele ? (
                    <img
                      src={DEFAULT_API_IMAGE + `${commande?.modele}`}
                      alt={commande.nom_tissu}
                      height="160"
                      style={{
                        objectFit: "cover",
                        margin: "auto",
                        width: "100%",
                      }}
                    />
                  ) : (
                    <BsImageFill size={80} color="#E82828" />
                  )}
                </div>
                <br></br>
                <br></br>
                <div className="com">
                  <span className="small-title ">Montant de la commande</span>
                  <span>{commande?.montant} Fcfa</span>
                </div>
                <div className="com">
                  <span className="small-title ">Montant payé</span>
                  <span>{commande?.montant_paye} Fcfa</span>
                </div>
                <div className="com">
                  <span className="small-title ">Restant </span>
                  <span>{commande?.montant_restant} Fcfa</span>
                </div>
                <div className="mx-2">
                  {/* <span></span> */}
                  {showPaymentMessage ? (
                    <p
                      style={{
                        fontWeight: "400",
                        fontFamily: "Montserrat",
                        color: "#12C28D",
                      }}
                    >
                      Paiement effectué 👌
                    </p>
                  ) : (
                    <ModalPaiement
                      onClose={handleModalHide}
                      isOpen={isModalOpen}
                      onShowModal={handleShowModal}
                      commandeId={commande?.id}
                      setIsModalOpen={setIsModalOpen}
                    />
                  )}

                  {/* <CustomizedButtons /> */}
                </div>
                <div
                  className="profiles mx-2"
                  style={{ color: "red" }}
                  onClick={() =>
                    navigate(`/historique-commande/${commande?.id}`)
                  }
                >
                  <div className="iconText ">
                    <HistoryIcon />
                    <span className="text mx-3" style={{ color: "red" }}>
                      Voir les historique
                    </span>
                  </div>
                </div>
                {/* </div> */}
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default DetailCommande;
