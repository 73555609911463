import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BsHouse, BsBox, BsPeople } from "react-icons/bs";
import "./bottomTab.css";
import "../../utils/GlobalStyle.css";
import {
  ACCUEIL_PATH,
  CLIENTS_PATH,
  COMMANDES_PATH,
} from "../../routes/path/navigation_path";

const BottomTab = () => {
  const location = useLocation();

  const isCommandesActive = location.pathname.includes("commande");
  const isClientsActive = location.pathname.includes("client");

  const bottomTabStyle = {
    maxWidth: "500px",
    height: "50px",
    flexShrink: 0,
    boxShadow: "0px 4.032px 30.24px 0px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 16px", // Espace intérieur pour le contenu
    position: "fixed", // Fixer le header en haut de la page
    width: "100%", // Occupe toute la largeur
    bottom: "0",
  };
  const labelStyle = {
    fontSize:"12px"
  };
  const iconStyle = {
    fontSize:"16px"
  };

  return (
    <nav style={bottomTabStyle}>
      <NavLink
        to={ACCUEIL_PATH}
        className={`bottom-tab-link ${location.pathname === ACCUEIL_PATH ? "active-link" : ""}`}
      >
        <BsHouse style={iconStyle}/>
        <span style={labelStyle}>Accueil</span>
      </NavLink>
      <NavLink
        to={COMMANDES_PATH}
        className={`bottom-tab-link ${isCommandesActive ? "active-link" : ""}`}
      >
        <BsBox style={iconStyle}/>
        <span style={labelStyle}>Commandes</span>
      </NavLink>
      <NavLink
        to={CLIENTS_PATH}
        className={`bottom-tab-link ${isClientsActive ? "active-link" : ""}`}
      >
        <BsPeople style={iconStyle}/>
        <span style={labelStyle}>Clients</span>
      </NavLink>
    </nav>
  );
};

export default BottomTab;
